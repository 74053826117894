import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import * as Yup from "yup";
import {imgPasswordVisible , imgPasswordInVisible } from "./assets"
import MuiAlert from '@material-ui/lab/Alert';
import React from "react";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  // Customizable Area Start
  history: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  accountType: string;
  emailSchema: any;
  phoneSchema: any;
  otpSchema: any;
  passwordSchema: any;
  accountStatus: any;
  passwordRules: any;
  emailValue: any;
  phoneValue: any;
  countryCodeSelected: any;
  token: any;
  enablePasswordField: boolean;
  enableReTypePasswordField: boolean;
  password: string;
  rePassword: string;
  email: string;
  emailError: string;
  passwordError: string;
  rePasswordError: string;
  passwordOtp: string;
  passwordOtpError: string;
  popupMessage: boolean;
  errStatus: boolean;
  responseData: string;
  apiSuccess: boolean;
  isButtonDisabled: boolean;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  navigation: any;
  // Customizable Area End
}

// Customizable Area Start
// Customizable Area End

export default class ForgotPasswordController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  validationAPICallId: any;
  emailReg: RegExp;
  numberReg: RegExp;
  passwordReg: RegExp;
  requestEmailOtpCallId: any;
  requestNewPasswordCallId: any;
  requestPhoneOtpCallId: any;
  getOtpCallId: string = "";
  requestChangePasswordCallId: any;
  requestGoToConfirmationCallId: any;
  otpToken: any;
  isChangePassword: boolean = false;

  //Properties from config
  labelTextIsAccountRecovery: string = configJSON.labelTextIsAccountRecovery;
  secondLabelText: string = configJSON.secondLabelText;
  thirdLabelText: string = configJSON.thirdLabelText;
  forthLabelText: string = configJSON.forthLabelText;
  fifthLabelText: string = configJSON.fifthLabelText;
  sixthLabelText: string = configJSON.sixthLabelText;
  firstInputAutoCompleteType: any = configJSON.firstInputAutoCompleteType;
  firstInputKeyboardStyle: any = configJSON.firstInputKeyboardStyle;
  firstInputPlaceholder: string = configJSON.firstInputPlaceholder;
  firstInputErrorColor: any = configJSON.firstInputErrorColor;
  buttonTextIsNext: string = configJSON.buttonTextIsNext;
  buttonColorForNextButton: any = configJSON.buttonColorForNextButton;
  secondInputAutoCompleteType: any = configJSON.secondInputAutoCompleteType;
  secondInputKeyboardType: any = configJSON.secondInputKeyboardType;
  secondInputPlaceholder: string = configJSON.secondInputPlaceholder;
  secondInputErrorColor: any = configJSON.secondInputErrorColor;
  thirdInputPlaceholder: string = configJSON.thirdInputPlaceholder;
  thirdInputErrorColor: any = configJSON.thirdInputErrorColor;
  buttonTitleIsSMSPhoneAccount: string =
    configJSON.buttonTitleIsSMSPhoneAccount;
  buttonTitleIsEmailAccount: string = configJSON.buttonTitleIsEmailAccount;
  labelTextIsPleaseEnterYourNewPassword: string =
    configJSON.labelTextIsPleaseEnterYourNewPassword;
  labelTextIsYourPasswordHasBeenSuccessfullyChanged: string =
    configJSON.labelTextIsYourPasswordHasBeenSuccessfullyChanged;
  placeholderIsPassword: string = configJSON.placeholderIsPassword;
  imgPasswordInVisible : any = imgPasswordInVisible ;
  imgPasswordVisible : any = imgPasswordVisible ;
  placeholderIsReTypePassword: string = configJSON.placeholderIsReTypePassword;
  buttonTitleIsOk: string = configJSON.buttonTitleIsOk;
  buttonColorForOkButton: any = configJSON.buttonColorForOkButton;
  countryCodeSelectorPlaceholder: string =
    configJSON.countryCodeSelectorPlaceholder;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage)
      // Customizable Area End
    ];

    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    //email schema
    let emailSchema = {
      email: Yup.string()
        .email(configJSON.pleaseEnterAValidEmail)
        .required(configJSON.emailIsRequired)
    };

    //phone schema
    let phoneSchema = {
      // countryCode: Yup.number()
      // .required("Country code is required"),

      phone: Yup.string()
        .matches(configJSON.phoneRegExp, configJSON.phoneNumberIsNotValid)
        .required(configJSON.phoneNumberIsRequired)
    };

    //otpSchema
    let otpSchema = {
      otpCode: Yup.number()
        .min(4)
        .required(configJSON.otpCodeIsRequired)
    };
     
    	
    this.emailReg = new RegExp(configJSON.emailRegs);
    this.numberReg = new RegExp(configJSON.numberRegs);
    this.passwordReg = new RegExp(configJSON.passwordRegs);
    
    this.state = {
      accountType: "sms",
      accountStatus: "ChooseAccountType",
      emailValue: "",
      phoneValue: "",
      countryCodeSelected: "",
      passwordRules: "",
      emailSchema: emailSchema,
      phoneSchema: phoneSchema,
      otpSchema: otpSchema,
      passwordSchema: "",
      token: "",
      enablePasswordField: true,
      enableReTypePasswordField: true,
      password: "",
      rePassword: "",
      email: "",
      emailError: "",
      passwordError: "",
      rePasswordError: "",
      passwordOtp: "",
      passwordOtpError: "",
      popupMessage: false,
      errStatus: false,
      responseData: "",
      apiSuccess: false,
      isButtonDisabled: false
    };
    // Customizable Area End
  }
  
  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
  }

  async receive(from: string, message: Message) {

    this.emailVerificationResponse(from, message);
    this.resetPasswordResponse(from, message);
    this.getPasswordOtpResponse(from, message);  
    
  }

  emailVerificationResponse = (from: string, message: Message) => {
    if (this.requestEmailOtpCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage),
      );

      const errorResponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage),
      );

      if (apiResponse) {
        if (apiResponse.errors) {
          this.setState({
            email: "", 
            popupMessage: true, 
            errStatus: true,
            responseData: apiResponse.errors[0].otp,
            isButtonDisabled: false
          });
        }else {
          this.setState({token: apiResponse.meta.token})
          if (this.state.token !== "") {
            global.localStorage.setItem("forgot-token", this.state.token);
            this.props.history.push("/ForgotPasswordOTP");
          }
        }
      } else if (errorResponse) {
        this.setState({
          email: "", 
          popupMessage: true, 
          errStatus: true,
          responseData: errorResponse.errors[0].otp
        });
      }
    }
  }

  getPasswordOtpResponse = (from: string, message: Message) => {
      if (this.getOtpCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
        const apiResponse = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage),
        );

        const errorResponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage),
        );

        if (apiResponse) {
          if (apiResponse.errors) {
            this.setState({
              passwordOtp: "", 
              popupMessage: true, 
              errStatus: true,
              responseData: apiResponse.errors[0].otp || apiResponse.errors[0].pin
            });
          }else {
              this.props.history.push("/NewPassword");
          }
        } else if (errorResponse) {
          this.setState({
            email: "", 
            popupMessage: true, 
            errStatus: true,
            responseData: errorResponse.errors[0].otp
          });
        }
      }
  }

  resetPasswordResponse = (from: string, message: Message) => {
    if (this.requestNewPasswordCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const successResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage),
      );

      const errorResponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage),
      );

      if (successResponse) {
        if (successResponse.errors) {
          this.setState({
            password: "", 
            rePassword: "",
            popupMessage: true, 
            errStatus: true,
            responseData: successResponse.errors[0].token || successResponse.errors[0].otp
          });
        } else {
          this.setState({popupMessage: true, errStatus: false,
            responseData: "Password is successfully changed"});
          global.localStorage.removeItem("forgot-token");
          setTimeout(() => {
            this.props.history.push("/");
         }, 2000);
        }
      }else if (errorResponse) {
        this.setState({
          email: "", 
          popupMessage: true, 
          errStatus: true,
          responseData: errorResponse.errors[0].otp
        });
    }
  }
}

  sendVerificationEmail = () => {

    if (this.state.email === "") {
      this.setState({ emailError: configJSON.errorEmailRequired });
    }

    if (this.state.email === '') {
      return false;
    }
    
    if(!this.state.isButtonDisabled) {
      const header = {
        "Content-Type": configJSON.forgotPasswordAPiContentType
      };
   
      const data = {
        type: "email_account",
        attributes: {
          login: this.state.email
        }
      };
   
      const httpBody = {
        data: data
      };
   
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
   
      this.requestEmailOtpCallId = requestMessage.messageId;
      
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.emailVerifyOtpAPiEndPoint
      );
   
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
   
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );
   
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpPostMethod
      );
   
      runEngine.sendMessage(requestMessage.id, requestMessage);
      this.setState({isButtonDisabled: true})
      return true;
    }
  }

  sendVerificationEmailOtp = () => {

    const token = localStorage.getItem("forgot-token");

    if (this.state.passwordOtp === "") {
      this.setState({ passwordOtpError: configJSON.errorPasswordOTPRequired });
    }

    if (this.state.passwordOtp === '') {
      return false;
    }

    const header = {
     "Content-Type": configJSON.forgotPasswordAPiContentType
    };

    const data = {
      otp_code: this.state.passwordOtp,
      token: token
    };

    const httpBody = {
      data: data
    };

   const requestMessage = new Message(
     getName(MessageEnum.RestAPIRequestMessage)
   );

   this.getOtpCallId = requestMessage.messageId;
   
   requestMessage.addData(
     getName(MessageEnum.RestAPIResponceEndPointMessage),
     configJSON.getOtpApiEndPoint
   );

   requestMessage.addData(
     getName(MessageEnum.RestAPIRequestHeaderMessage),
     JSON.stringify(header)
   );

   requestMessage.addData(
     getName(MessageEnum.RestAPIRequestBodyMessage),
     JSON.stringify(httpBody)
   );

   requestMessage.addData(
     getName(MessageEnum.RestAPIRequestMethodMessage),
     configJSON.httpPostMethod
   );

   runEngine.sendMessage(requestMessage.id, requestMessage);
   return true;
  }

  handleStatus(){
    if (this.state.errStatus) {
      return "error";
    }else {
      return "success";
    }
  }


  Alert(props: any) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  changePasswordType = () => {
    if (this.state.enablePasswordField) {
      return "password";
    }else {
      return "text";
    }
  }

  changeConfirmPasswordType = () => {
    if (this.state.enableReTypePasswordField) {
      return "password";
    }else {
      return "text";
    }
  }

  handleResetPassword = () => {
    
    const token = localStorage.getItem("forgot-token");

    if (this.state.password === "") {
      this.setState({ passwordError: configJSON.errorPasswordRequired });
    } else if (this.state.rePassword === "") {
      this.setState({ rePasswordError: configJSON.errorPasswordMatch });
    } else if (this.state.rePassword !== this.state.password) {
      this.setState({ rePasswordError: configJSON.errorPasswordMatch });
    }

    if (this.state.password === "" ||
      this.state.rePassword === "" || 
      this.state.rePassword !== this.state.password) {
        return false;
    }

    const header = {
      "Content-Type": configJSON.forgotPasswordAPiContentType
    };

    const data = {
      token: token,
      new_password: this.state.password
    };

    const httpBody = {
      data: data
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.requestNewPasswordCallId = requestMessage.messageId;
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.newPasswordApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleChangeemail = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
    ) => {
      this.setState({ email: event.target.value });
      if (event.target.value === "") {
        this.setState({ emailError: configJSON.errorEmail });
      } else if (!this.emailReg.test(event.target.value)) {
        this.setState({ emailError: configJSON.errorEmail });
      } else {
        this.setState({ emailError: "" });
      }
  };

  handleChangePassword = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      this.setState({ password: event.target.value });
      if (event.target.value === "") {
        this.setState({ passwordError: configJSON.errorPasswordRequired });
      } else if (!this.passwordReg.test(event.target.value)) {
        this.setState({ passwordError: configJSON.errorPasswordFormat });
      } else {
        this.setState({ passwordError: "" });
      }
  };

  handleChangeRepassword = (event: { target: { value: string } }) => {
      this.setState({ rePassword: event.target.value });
      if (event.target.value === this.state.password) {
        this.setState({ rePasswordError: "" });
      } else if (event.target.value === "") {
        this.setState({ rePasswordError: configJSON.errorPasswordMatch });
      } else if (event.target.value.length < 8) {
        this.setState({ rePasswordError: configJSON.errorPasswordMatch });
      } else {
        this.setState({ rePasswordError: configJSON.errorPasswordMatch });
      }
  };

  handlePasswordOtp = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    this.setState({ passwordOtp: event.target.value });
    if (event.target.value === "") {
      this.setState({ passwordOtpError: configJSON.errorPasswordOTPRequired });
    } else if (!this.numberReg.test(event.target.value)) {
      this.setState({ passwordOtpError: configJSON.errorPasswordOTPValid });
    } else {
      this.setState({ passwordOtpError: "" });
    }
  };

  enablePasswordShow = () => {
    this.setState({enablePasswordField: !this.state.enablePasswordField})
  }

  enableConfirmPasswordShow = () => {
    this.setState({enableReTypePasswordField: !this.state.enableReTypePasswordField})
  }

  handleCloseSnackBar = () => {
    this.setState({  popupMessage: false , errStatus: false, responseData: ''});
  }

  myFunction = () => {
    this.enablePasswordShow();
    this.enableConfirmPasswordShow();
    this.goToLogin();
    this.goToHome();
    this.Alert(this.props);
    this.handleCloseSnackBar();
    this.changePasswordType();
    this.changeConfirmPasswordType();
    this.handleStatus();
  }

  goToHome() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationHomeScreenMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  goToLogin() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationEmailLogInMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  handleSubmit(actions: any, values: any) {
  }

  // Customizable Area End
}
