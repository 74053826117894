import React from "react";
// Customizable Area Start
import {
  Box,
  Typography,
  Card,
  CardContent,
  Button,
  Divider,
} from "@material-ui/core";
import RewardsController, { Props, configJSON } from "./RewardsController.web";
import Navbar from "../../../components/src/Navbar.web";
import { styled } from "@material-ui/styles";
import { star, boxIcon, rightArrow, yellowIcon, orangeIcon } from "./assets";

// Customizable Area End

//Customizable Area Start

const RewardsWrapper = styled("div")({
  color: "#FAFAFA",
  backgroundColor: "#1B122E",
  height: "auto",
  "@media (min-height: 900px)": {
    height: "100vh",
  },
  "& .starLogo": {
    width: "18px",
    position: "absolute",
    top: "-15px",
    "@media (max-width:600px)": {
      width: "18px",
    },
  },
  "& .rewards-title": {
    position: "relative",
    display: "block",
    fontFamily: "Figtree, sans-serif",
    fontSize: "20px",
    fontWeight: 700,
    "@media (max-width:600px)": {
      fontSize: "16px",
      fontWeight: 700,
      lineHeight: "24px",
      paddingBottom: "8px",
    },
  },
  "& .cardContent": {
    backgroundColor: "#261E38",
    padding: "24px",
    width: "320px",
    borderRadius: "24px",
    marginTop: "24px",
    "@media (max-width:600px)": {
      width: "83%",
      margin: "0px auto",
      fontSize: "16px",
      fontWeight: 700,
    },
  },
  "& .rewards-point-wrapper": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "& .rewards-point": {
      width: "48%",
      textAlign: "center",
      "& .point-title": {
        color: "#fafafacc",
        fontFamily: "Figtree, sans-serif",
        fontSize: "12px",
        fontWeight: 500,
      },
      "& .point-value": {
        color: "#FC6D52",
        fontFamily: "Figtree, sans-serif",
        fontSize: "24px",
        fontWeight: 700,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      },
    },
    "& .rewards-line": {
      width: "1px",
      height: "56px",
      background: "#3b334c",
    },
  },
  "& .submit-btn": {
    width: "100%",
    backgroundColor: "yellow",
    borderRadius: "24px",
    padding: "8px",
    textTransform: "none",
    letterSpacing: "1px",
    fontSize: "14px",
    color: "#1B122E",
    fontWeight: 700,
    fontFamily: "Figtree, sans-serif",
    marginTop: "16px",
    position: "unset",
  },
  "& .cardcontent-data": {
    width: "100%",
    height: "auto",
    backgroundColor: "#261E38",
    borderRadius: "24px",
  },
  "& .cardtitle": {
    marginTop: "45px",
    marginBottom: "9px",
    fontSize: "20px",
    color: "#FAFAFA",
    lineHeight: "24px",
    fontWeight: 700,
    fontFamily: "Figtree, sans-serif",
    "@media (max-width:600px)": {
      fontSize: "16px",
      fontWeight: 700,
      fontFamily: "Figtree, sans-serif",
    },
  },
  "& .imageContent": {
    width: "36px",
    height: "36px",
  },
  "& .cardfirstdata": {
    fontSize: "14px",
    color: "#FAFAFA",
    lineHeight: "20px",
    fontWeight: 500,
    fontFamily: "Figtree, sans-serif",
    "@media (max-width:600px)": {
      fontSize: "16px",
      fontWeight: 500,
      fontFamily: "Figtree, sans-serif",
    },
  },
  "& .cardseconddata": {
    fontSize: "12px",
    color: "#fafafa80",
    lineHeight: "16px",
    fontWeight: 500,
    margin: "10px 0px",
    fontFamily: "Figtree, sans-serif",
    "@media (max-width:600px)": {
      display: "none",
    },
  },
  "& .cardseconddata-mobile": {
    fontSize: "12px",
    color: "#fafafa80",
    lineHeight: "16px",
    fontWeight: 500,
    margin: "10px 0px",
    fontFamily: "Figtree, sans-serif",
    display: "none",
    "@media (max-width:600px)": {
      display: "inline-block",
    },
  },
  "& .dividercontent": {
    backgroundColor: "rgba(250, 250, 250, 0.12)",
    margin: "5px 0px",
  },
  "& .card-main": {
    display: "flex",
    alignItems: "center",
    padding: "8px",
    width: "98.5%",
    justifyContent: "space-between",
    cursor: "pointer",
    "@media (max-width:600px)": {
      padding: "3px 4px 8px",
    }
  },
  "& .card-data": {
    width: "200px",
    marginLeft: "16px",
  },
  "& .submit-btn-notactive": {
    width: "100%",
    backgroundColor: "#3a324a",
    borderRadius: "24px",
    padding: "8px",
    textTransform: "none",
    letterSpacing: "1px",
    fontSize: "14px",
    color: "#94909d",
    fontWeight: 700,
    fontFamily: "Figtree, sans-serif",
    marginTop: "16px",
    position: "unset"
  },
  "& .no-data-found": {
    fontFamily: "Figtree, sans-serif",
    margin:"10px 6px",
    fontSize: "16px",
    fontWeight: 500,
    color: "#FAFAFA",
    "@media (max-width:600px)": {
      fontSize: "14px"
    }
  },
});
//Customizable Area End

export default class Rewards extends RewardsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  imgWithStatus(type: string) {
    let img;
    if (type === "Approved") {
      img = boxIcon;
    } else if (type === "Not Approved") {
      img = yellowIcon;
    } else {
      img = orangeIcon;
    }
    return <img src={img} className="imageContent" />;
  }

  render() {
    const { cardDataValue } = this.state;
    return (
      // Required for all blocks
      <RewardsWrapper>
        <Navbar
          // @ts-ignore
          params={"Rewards"}
          headerText={"Rewards"}
          history={this.props.history}
          contact_us={this.state.contact_us}
        />
        <Box sx={{ width: "90%", margin: "50px auto" }}>
          <Typography variant="h5" className="rewards-title">
            {configJSON.Rewards}
            <img className="starLogo" src={star} alt="star" />
          </Typography>
          <Card className="cardContent">
            <Box className="rewards-point-wrapper">
              <div className="rewards-point">
                <Typography className="point-title">
                  {configJSON.point_earned}
                </Typography>
                <Typography className="point-value">
                  {this.state.totalpointsEarnValue}
                </Typography>
              </div>
              <div className="rewards-line" />
              <div className="rewards-point">
                <Typography className="point-title">
                  {configJSON.RewardsValue}
                </Typography>
                <Typography className="point-value">
                  {this.state.totalrewardValue}
                  <span style={{ fontSize: "14px" }}>
                    {configJSON.currency_sign}
                  </span>
                </Typography>
              </div>
            </Box>
            <Button
              variant="contained"
              className={
                this.state.totalpointsEarnValue !== 0 ? "submit-btn" : "submit-btn-notactive"
              }
              disabled={this.state.totalpointsEarnValue !== 0 ? false : true}
              data-test-id="open-upload-dialog"
              onClick={this.handleReedim}
            >
              {configJSON.Redeem}
            </Button>
          </Card>

          {cardDataValue && cardDataValue.length > 0 &&
            cardDataValue.map(
              (card: {
                title: string;
                item: {
                  area: string;
                  content: string;
                  desc: string;
                  id: number;
                  status: string;
                }[];
              }) => {
                return (
                  <Box key={card.title}>
                    <Typography className="cardtitle">{card.title}</Typography>
                    <Card className="cardcontent-data">
                      <CardContent style={{ padding: "8px 16px" }}>
                        {this.state.dataTotal.length > 0 ?
                         ( this.state.dataTotal.map(
                            (
                              data: {
                                area: string;
                                content: string;
                                desc: string;
                                id: number;
                                status: string;
                              },
                              index: number,
                              array: {}[]
                            ) => {
                              return (
                                <>
                                  <Box
                                    className="card-main"
                                    data-test-id="details-div"
                                    onClick={() => {
                                      this.handleRewardsStatus(data);
                                    }}
                                  >
                                    <Box
                                      sx={{
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      {this.imgWithStatus(data.status)}
                                      <Box className="card-data">
                                        <Typography
                                          className="cardseconddata-mobile"
                                          component="div"
                                        >
                                          {data.content.substring(0, 66)}
                                          {data.content.length > 66
                                            ? "..."
                                            : ""}
                                        </Typography>
                                        <Typography
                                          className="cardfirstdata"
                                          component="div"
                                        >
                                          Status: {data.status}
                                        </Typography>
                                        <Typography
                                          className="cardseconddata"
                                          component="div"
                                        >
                                          {data.content.substring(0, 66)}
                                          {data.content.length > 66
                                            ? "..."
                                            : ""}
                                        </Typography>
                                      </Box>
                                    </Box>
                                    <Box>
                                      <img
                                        src={rightArrow}
                                        className="right-arrow-img"
                                      />
                                    </Box>
                                  </Box>
                                  {array.length - 1 != index && (
                                    <Divider
                                      className="dividercontent"
                                      variant="middle"
                                    />
                                  )}
                                </>
                              );
                            }
                          )): (<Typography className="no-data-found">No data found</Typography>)}
                      </CardContent>
                    </Card>
                  </Box>
                );
              }
            )}
        </Box>
      </RewardsWrapper>
    );
  }
}
