import React from "react";
import { Box, Grid } from "@material-ui/core";
import { styled } from "@material-ui/styles";
import Sidebar from "../../../components/src/sidebar.web";
import Card from "../../../components/src/Card.web";
import  DashboardPageEmployerController  from "./DashBordPageEmployerController.web";

export const DashboardStyle = styled(Box)({
  "& *": {
    boxSizing: "border-box"
  },
  display: "flex",
  height: "100%",
  backgroundColor: "#1B122E",
  "& main": {
    width: "calc(100% - 240px)",
    height: "100%",
    overflow: "auto",
    padding: "48px 24px",
    "& .heading": {
      display: "flex",
      alignItems: "center",
      gap: "16px",
      color: "#FFFFFF",
      "& h2": {
        fontWeight: "600",
        fontSize: "36px",
        lineHeight: "44px",
        color: "#FFFFFF",
        margin: "0"
      },
      "& .icon": {
        padding: "6px"
      },
      "&.between": {
        justifyContent: "space-between",
        color:"red"
      },
      "& .exportBtn": {
        border: "none",
        outline: "none",
        background: "#ECEF51",
        boxshadow:
          "0px 13px 50px rgba(0, 0, 0, 0.09), 0px 2px 8px rgba(0, 0, 0, 0.08), 0px 4px 32px rgba(0, 0, 0, 0.06)",
        borderRadius: "24px 4px",
        fontWeight: "700",
        fontSize: "16px",
        lineHeight: "24px",
        padding: "10px 32px",
        cursor: 'pointer'
      }
    },
    "& .body": {
      marginTop: "40px"
    }
  }
});
export default class Engagement extends DashboardPageEmployerController {
  // constructor(){
  //     su
  // }
  render() {
    return (
      <DashboardStyle>
        <Sidebar active={1} history={this.props.history} handleChange={this.handleChange} employerData={this.state.employerData} handleChangeEmployerData={this.handleChangeEmployerData} handleUpdateEmployer={this.handleUpdateEmployer} errorMessage={this.state.errorMessage} fieldRequiredData={this.state.fieldRequiredData} handleClearData={this.handleClearData} switchData={this.state.switchData} errorMsgPasValidation={this.state.errorMsgPasValidation} handleEmployerData={this.handleEmployerData} contactUsData={this.state.contactUsData}/>
        <main>
          <div className="heading">
            <h2 data-test-id="heading">Employee Engagement</h2>
            <span className="icon">
              <svg
                width="34"
                height="34"
                viewBox="0 0 34 34"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_1798_12455)">
                  <path
                    d="M23.875 29.375V26.625C23.875 25.1663 23.2955 23.7674 22.2641 22.7359C21.2326 21.7045 19.8337 21.125 18.375 21.125H7.375C5.91631 21.125 4.51736 21.7045 3.48591 22.7359C2.45446 23.7674 1.875 25.1663 1.875 26.625V29.375"
                    stroke="currentColor"
                    stroke-width="2.75"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M12.875 15.625C15.9126 15.625 18.375 13.1626 18.375 10.125C18.375 7.08743 15.9126 4.625 12.875 4.625C9.83743 4.625 7.375 7.08743 7.375 10.125C7.375 13.1626 9.83743 15.625 12.875 15.625Z"
                    stroke="currentColor"
                    stroke-width="2.75"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M32.125 29.3759V26.6259C32.1241 25.4073 31.7185 24.2235 30.9719 23.2604C30.2253 22.2972 29.1799 21.6093 28 21.3047"
                    stroke="currentColor"
                    stroke-width="2.75"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M22.5 4.80469C23.6831 5.1076 24.7317 5.79565 25.4805 6.76036C26.2293 7.72508 26.6358 8.91158 26.6358 10.1328C26.6358 11.354 26.2293 12.5405 25.4805 13.5053C24.7317 14.47 23.6831 15.158 22.5 15.4609"
                    stroke="currentColor"
                    stroke-width="2.75"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_1798_12455">
                    <rect
                      width="33"
                      height="33"
                      fill="currentColor"
                      transform="translate(0.5 0.5)"
                    />
                  </clipPath>
                </defs>
              </svg>
            </span>
          </div>
          <div className="body">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Card title="Employees Signed Up">
                  <span className="number">{this.state.engagementData.employees_signed_up}</span>
                </Card>
              </Grid>
              <Grid item xs={6}>
                <Card title="Solutions selected">
                  <span className="number">{this.state.engagementData.solutions_selected}</span>
                </Card>
              </Grid>
              <Grid item xs={6}>
                <Card title="Commitment Created">
                  <span className="number">{this.state.engagementData.commitment_created}</span>
                </Card>
              </Grid>
              <Grid item xs={6}>
                <Card title="Reviews submitted">
                  <span className="number">{this.state.engagementData.reviews_submitted}</span>
                </Card>
              </Grid>
          
              <Grid item xs={6}>
                <Card title="Verified actions">
                  <span className="number">{this.state.engagementData.verified_actions}</span>
                </Card>
              </Grid>
              <Grid item xs={6}>
                <Card title="Incentives allocated">
                  <span className="number">{this.state.engagementData.incentives_allocated}</span>
                </Card>
              </Grid>
              <Grid item xs={6}>
                <Card title="Incentives disbursed">
                  <span className="number">{this.state.engagementData.incentives_disbursed}</span>
                </Card>
              </Grid>
            </Grid>
          </div>
        </main>
      </DashboardStyle>
    );
  }
}
