import React, { Component } from 'react';
import Typography from '@material-ui/core/Typography';
import { Box, Button, Dialog } from '@material-ui/core';
import { logo, userlogo, toggle, cross, userProfileMobile, aboutEzeo, focusArea, rewards, contactUs, settings, logOut, previous, userlogo2, userlogo3, home } from './assets'
import Hidden from "@material-ui/core/Hidden";
import { styled } from "@material-ui/styles";
import DialogActions from "@material-ui/core/DialogActions";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { withRouter } from "react-router-dom";
import { Message } from "../../../packages/framework/src/Message";
import { BlockComponent } from "../../../packages/framework/src/BlockComponent";
import { runEngine } from "../../../packages/framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../packages/framework/src/Messages/MessageEnum";
type MyState = { getAcconuttypeID: string, sidebar: boolean, showDropDown: boolean, settingDialog: string, dialog: boolean, setting: boolean, settingsArray: string[], mobileNav: { contactUs: boolean, setting: boolean, ezeo: boolean } };
type MyProps = { userName: any, profielType: any, headerText: string; params: string; history: any; rewardContactUs: boolean; getAvatarURl?: () => string; handleSettingProfile?: (value: boolean, response: any) => void; contact_us:any};


const NavigationBlock = styled("div")(({ theme }) => ({
  "--navBg": "#1B122E",
  backgroundColor: "#1B122E",

  '& .webNav': {
    justifyContent: 'space-between',
    color: "#FCFCFC",
    alignItems: 'center',
    cursor: "pointer"
  },

  '& .itemWrapper': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },

  '& .historyLogo': {
    width: "180x",
    height: "40px"
  },
  "& .header-text": {
    color: "#fc6d52",
    fontFamily: "Figtree, sans-serif",
    "@media(max-width:600px)": {
      fontSize: "22px",
      fontWeight: 700
    }
  },
  '& .first-nav': {
    marginLeft: "72px !important",
  },
  '& .navSubFonts': {
    fontSize: "16px",
    fontWeight: 500,
    weight: "78px",
    height: "24px",
    marginLeft: "56px",
    whiteSpace: 'nowrap',
    color: "#fafafa80",
  },
  "& .active-nav:after": {
    content: "' '",
    display: "block",
    position: "relative",
    width: "95%",
    border: "2px solid #FC6D52",
    borderRadius: "5px",
  },
  "& .active-nav": {
    color: "#FAFAFA !important"
  },
  '& .profile': {
    borderRadius: "50%",
  },
  '& .toggle': {
    position: "absolute",
    left: 25
  },
  '& .nav-menu': {
    backgroundColor: 'var(--navBg)',
    width: '100%',
    height: '100%',
    padding: "24px 0px",
    position: 'fixed',
    top: '0',
    left: '-100%',
    transition: '850ms',
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    zIndex: 10,
    justifyContent: 'space-between',
    '& .profile-detail': {
      display: 'flex',
      alignItems: 'center',
      gap: '16px',
      margin: '32px 0',
      '& .details': {
        fontFamily: "Figtree, sans-serif",
        display: 'flex',
        flexDirection: 'column',
        gap: '6px',
        color: '#fff',
        '& .name': {
          fontWeight: '700',
          fontSize: '20px',
          lineHeight: '24px',
          color: '#FAFAFA',
        },
        '& .email': {
          fontWeight: '400',
          fontSize: '14px',
          lineHeight: '20px',
          color: '#FAFAFA',
          opacity: '0.6,'
        }
      },
    },

    '& .nav-menu-items': {
      fontFamily: "Figtree, sans-serif",
      width: '100%',
      padding: '0',
      display: "flex",
      flexDirection: 'column',
      gap: "25px",
      color: '#fafafa',
      listStyleType: 'none',
      '& li': {
        display: 'flex',
        alignItems: 'center',
        gap: '20px'
      }
    },
    '& .logout': {
      display: 'flex',
      alignItems: 'center',
      gap: '20px',
      '& span': {
        fontWeight: '500',
        fontSize: '16px',
        lineHeight: '24px',
        color: '#FAFAFA',
      }
    }
  },
  '& .nav-menu.active': {
    left: '0',
    transition: '350ms',
    zIndex: 10
  },
  "& .dropdown-list": {
    fontFamily: "Figtree, sans-serif",
    position: "absolute",
    maxHeight: "400px",
    top: "0px",
    right: "-63px",
    backgroundColor: "#3A324B",
    borderRadius: "20px",
    borderColor: "1px solid rgba(0, 0, 0, 0.2)",
    listStyle: "none",
    transition: "all 0.3s ease-out",
    fontSize: "16px",
    width: "222px",
    zIndex: 100,
    padding: "5px",
    cursor: "pointer",
  },
  "& .dropdown-list li": {
    padding: "0px 20px",
    margin: "7px 0px",
  },
  "& .dropdown-list li:last-of-type a": {
    border: "none",
  },
  "& .dropdown-list a": {
    margin: "4px",
    display: "inline-block",
    textDecoration: "none",
    fontSize: "16px",
    color: "#FAFAFA",
    padding: " 10px 0",
    transition: "all 0.3s ease-out",
    zIndex: 100,
  },
  "& .dropdwon-box": {
    position: "relative",
    "& .dropdown-wrapper": {
      position: "absolute"
    }
  },
  "& .dropdown-item": {
    display: "flex",
    alignItems: "center"
  },
  "& .dropdown-img": {
    marginRight: "6px"
  },
  "& .mobile-sidebar": {
    padding: "0px 24px"
  },
  "& .message-text": {
    fontSize: "16px",
    fontWeight: 400,
    padding: "7px 37px 33px 4px",
    color: "#FFF",
    "@media (max-width:600px)": {
      fontSize: "14px",
      fontWeight: 400,
      margin: "10px 0px",
      padding: "0px",
    }
  }
}))

const ContactStyle = styled('div')({
  '& p': {
      '& a': {
          color: '#67ADFF'
      }
  }
})

const ModelWrapper = styled(Dialog)({
  "& .submit-btn": {
    borderRadius: "24px",
    padding: "12px 32px",
    fontSize: "16px",
    lineHeight: "24px",
    width: " 140px",
    height: "48px",
    background: "#3a324b",
    textTransform: "none",
    color: "#94909D",
    marginLeft: "16px"
  },
  "& .submit-action": {
    borderRadius: "24px",
    padding: "12px 32px",
    fontSize: "16px",
    lineHeight: "24px",
    width: " 140px",
    height: "48px",
    background: "#ECEF51",
    textTransform: "none",
    marginLeft: "16px",
    fontFamily: "Figtree, sans-serif"
  },
  "& .cancle-action": {
    borderRadius: "24px",
    padding: "12px 32px",
    fontSize: "16px",
    lineHeight: "24px",
    width: " 140px",
    height: "48px",
    color: "#FAFAFA",
    border: "1px solid #FAFAFA",
    textTransform: "none",
  },
  "& .model-title": {
    fontSize: "18px",
    fontWeight: 700,
    paddingBottom: "5px",
    "@media (max-width:600px)": {
      fontSize: "16px",
      fontWeight: 700,
    }
  },
  "& .setting-box": {
    display: "flex",
    justifyContent: "space-between"
  },
  "& .report-modal": {
    padding: "8px 10px 15px"
  }
});

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  borderRadius: "12px",
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#F0F0F0',

      '& + .MuiSwitch-track': {
        opacity: 1,
        border: 0,
        backgroundColor: "#D1BCE3",
        borderRadius: "13px",
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '& .MuiSwitch-thumb': {
      boxSizing: 'border-box',
      width: 22,
      height: 22,
    },
  }
}));



class Navbar extends Component<MyProps, MyState>{
  constructor(props: any) {
    super(props);
    this.state = {
      sidebar: false,
      showDropDown: false,
      settingDialog: "",
      dialog: false,
      setting: false,
      settingsArray: ["Settings", "About Ezeo", "Contact Us"],
      mobileNav: { contactUs: false, setting: false, ezeo: false },
      getAcconuttypeID: "",
    };
  }
  // async componentDidMount() {
  // }
  componentDidUpdate(prevProps: Readonly<MyProps>, prevState: Readonly<MyState>, snapshot?: any): void {
    const reward = this.props.rewardContactUs;
    if (reward !== prevProps.rewardContactUs) {
      if (reward) {
        this.setState({ setting: true, settingDialog: "Contact Us", sidebar: true })
      }
    }
  }

  handleClick = (value: any) => {
    if (this.state.settingsArray.includes(value)) {
      this.setState({ setting: true, settingDialog: value })
    } else {
      this.props.history.push({ pathname: value });
    }
  }

  handleDropdownOpen = () => {
    this.setState({ showDropDown: !this.state.showDropDown })
  }

  handleDropdownClick = (lable: string) => {
    this.setState({ dialog: true, settingDialog: lable })
  }

  handleCloseModel = () => {
    this.setState({ dialog: false })
  }

  handleLogout = () => {
    this.setState({ showDropDown: false, dialog: false })
    localStorage.removeItem('user_token');
    localStorage.clear();
    localStorage.setItem('Logout', 'true');
    this.props.history.push("/EmailAccountLoginBlock");
  }

  handleLeftSideAction = () => {
    if (this.state.settingDialog === "Logout") {
      this.handleLogout()
    } else {
      this.setState({ showDropDown: false, dialog: false })
    }
  }

  settingDialogDesc = () => {
    const setting = this.state.settingDialog;
    if (setting === "Settings") {
      return (
        <>
          <Typography variant="body2" className="message-text">Receive notifications via E-mail</Typography>
          <FormControlLabel control={<IOSSwitch sx={{ m: 1 }} defaultChecked />} label=" " style={{ margin: "0" }} />
        </>
      )
    }
    if (setting === "Contact Us") {
      return (
        <>
        <ContactStyle>
          {this.props.contact_us.length ? ( <Typography className="message-text" dangerouslySetInnerHTML={{ __html: this.props.contact_us }} />) : ((<Typography className="no-data-found">No Data available</Typography>))}
        </ContactStyle>
          {/* <Typography variant="body2" className="message-text" >For any issues you face please drop a mail to <a href='mailto:support@ezeo.earth' style={{ color: "#67ADFF" }}>support@ezeo.earth</a>. we will be happy to help you.</Typography> */}
        </>
      )
    }
    if (setting === "Logout") {
      return (
        <>
          <Typography variant="body2" className="message-text">Are you sure, do you want to logout ?</Typography>
        </>
      )
    }
  }

  handleNavigateDashboard = () => {
    this.props.history.push({ pathname: "EmployeeHome" });
  }

  renderSettingModel = () => {
    return (
      <ModelWrapper
        open={this.state.dialog}
        onClose={this.handleCloseModel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          style: {
            backgroundColor: "#2d253e",
            borderRadius: "24px",
            padding: "20px 19px 30px",
            color: "#fff",
            width: "315px"
          }
        }}
      >
        <Box className="report-modal">
          <Typography variant="body1" className="model-title">
            {this.state.settingDialog}
          </Typography>
          <Box className='setting-box'>
            {this.settingDialogDesc()}
          </Box>
        </Box>
        {this.state.settingDialog !== "Contact Us" ? <DialogActions className="action-btn">
          <Button
            className="cancle-action"
            variant="outlined"
            onClick={this.handleLeftSideAction}
          >
            {this.state.settingDialog === "Settings" ? "Cancel" : "Logout"}
          </Button>
          <Button
            className={"submit-action"}
            data-test-id="submit-btn"
            onClick={this.handleCloseModel}
            variant="contained"
          >
            {this.state.settingDialog === "Settings" ? "Save" : "Cancel"}
          </Button>
        </DialogActions> : ""}
      </ModelWrapper>
    );
  };

  sidebarToggle = (value: string) => {
    if (!this.state.settingsArray.includes(value)) {
      this.setState({ sidebar: false })
    }
  }

  NavigationItems = (params: string) => {
    const historyItems = [
      { id: 'EmployeeHome', label: 'Home' },
      { id: 'Categories', label: 'Focus area' },
      { id: 'Rewards', label: 'Rewards' },
      { id: 'AboutEzeo', label: 'About Ezeo' },
    ];

    return (
      <div className='itemWrapper'>
        <img src={logo} className='historyLogo' onClick={this.handleNavigateDashboard} />
        {historyItems.map((item) => (
          <Typography
            key={item.id}
            variant="h6"
            className={params === item.id ? 'navSubFonts first-nav active-nav' : 'navSubFonts first-nav'}
            onClick={() => this.handleClick(item.id)}
          >
            {item.label}
          </Typography>
        ))}
      </div>
    );
  };

  handleRewardsClick = () => {
    const { headerText } = this.props;
    if (headerText === "RewardsStatus" || headerText === "Payout") {
      this.handleClick("Rewards");
    } else {
      this.setState({ sidebar: true });
    }
  };
  render() {
    const { headerText, userName } = this.props;
    const { sidebar, setting, settingDialog } = this.state;
    const isRewards = headerText === "RewardsStatus";
    const showRewardsToggle = isRewards || headerText === "Payout";
    const showDashboardLogo = headerText === "Dashboard";
    const rewardsHeaderText = isRewards ? "Rewards" : headerText;
    const navMenuItems = [
      { image: home, name: "Home", pathname: "EmployeeHome" },
      { image: focusArea, name: "Focus Area", pathname: "Categories" },
      { image: rewards, name: "Rewards", pathname: "Rewards" },
      { image: aboutEzeo, name: "About Ezeo", pathname: "AboutEzeo" },
      { image: contactUs, name: "Contact Us", pathname: "Contact Us" },
      { image: settings, name: "Settings", pathname: "Settings" },
    ];
    const menuItems = [
      { label: "Settings", image: settings },
      { label: "Contact Us", image: contactUs },
      { label: "Logout", image: logOut },
    ];
  const profielType= localStorage.getItem("profielType")
  const name = localStorage.getItem("firstName")
  const email = localStorage.getItem("email")
    return (
      <NavigationBlock>
        {this.renderSettingModel()}
        <Box className='webNav' sx={{ display: { xs: 'none', md: 'flex' }, margin: "35px auto 15px auto", width: { xs: "90%" } }}>
          {this.NavigationItems(this.props.params)}
          <Box className="dropdwon-box">
            {profielType === 'Eco_Unaware' ?
              (<img src={userlogo} className='profile' onClick={this.handleDropdownOpen} />) :
              profielType === 'Eco_Conscious' ?
                (<img src={userlogo2} className='profile' onClick={this.handleDropdownOpen} />) :
                (profielType === 'Eco_Active' &&
                  <img src={userlogo3} className='profile' onClick={this.handleDropdownOpen} />)}<div
                    id="dropdown-wrapper"
                    data-test-id="sort-btn"
                    className="dropdown-wrapper"
                  >
              <Hidden only="xs">
                {this.state.showDropDown && (
                  <ul className="dropdown-list">
                    {menuItems.map((item) => (
                      <li
                        key={item.label}
                        id="dropdowndata"
                        className="dropdown-item"
                        data-test-id="dropdowndata"
                        onClick={() => this.handleDropdownClick(item.label)}
                      >
                        <img src={item.image} alt={item.label} className="dropdown-img" />
                        <a>{item.label}</a>
                      </li>
                    ))}
                  </ul>
                )}
              </Hidden>
            </div>
          </Box>
        </Box>
        <Box sx={{
          display: { xs: 'flex', md: 'none' },
          alignItems: 'center',
          justifyContent: 'center',
          position: 'relative',
          margin: '30px 0 15px 0',
        }}>
          {showRewardsToggle ? (
            <img src={previous} width={24} height={24} className='toggle' onClick={this.handleRewardsClick} />
          ) : (
            <img src={toggle} width={24} height={24} className='toggle' onClick={() => this.setState({ sidebar: true })} />
          )}
          {showDashboardLogo ? (
            <img src={logo} style={{ width: '139px' }} />
          ) : (
            <Typography variant="h4" className='header-text'>{rewardsHeaderText}</Typography>
          )}
        </Box>
        <div className={sidebar ? "nav-menu active" : "nav-menu"}>
          {!setting ? (
            <div className='mobile-sidebar'>
              <img src={cross} onClick={() => this.setState({ sidebar: false })} />
              <div className='profile-detail'>
              {profielType === 'Eco_Unaware' ?
              (<img src={userlogo} className='profile' onClick={this.handleDropdownOpen} />) :
              profielType === 'Eco_Conscious' ?
                (<img src={userlogo2}  className='profile' onClick={this.handleDropdownOpen} />) :
                (profielType === 'Eco_Active'&&
                 <img src={userlogo3} className='profile' onClick={this.handleDropdownOpen}  />)}
                <div className='details'>
                  <span className='name'>{name}</span>
                  <span className='email'>{email}</span>

                </div>
              </div>
              <ul className="nav-menu-items">
                {navMenuItems.map((item) => (
                  <li key={item.name} style={!item.pathname ? { color: "#ececed69" } : {}} onClick={() => item.pathname && this.sidebarToggle(`${item.pathname}`)}>
                    <img src={item.image} alt={item.name} />
                    <span onClick={() => item.pathname && this.handleClick(`${item.pathname}`)}>{item.name}</span>
                  </li>
                ))}
              </ul>
            </div>
          ) : (
            <Box>
              <Box sx={{ display: { xs: 'flex', md: "none" }, alignItems: 'center', justifyContent: "center", position: "relative", margin: "3px 0px 40px 0px" }}>
                <img src={toggle} width={24} height={24} className='toggle' onClick={() => this.setState({ setting: false, mobileNav: { contactUs: false, setting: false, ezeo: false } })} />
                <Typography variant="h4" className='header-text'>{settingDialog}</Typography>
              </Box>
              <Box style={{ display: "flex", justifyContent: "space-between", padding: "0px 24px" }}>
                {this.settingDialogDesc()}
              </Box>
            </Box>
          )}
          {!setting ? (
            <div className='logout mobile-sidebar' onClick={() => this.handleDropdownClick("Logout")}>
              <img src={logOut} alt="Log out" />
              <span>Log out</span>
            </div>
          ) : (
            ""
          )}
        </div>
      </NavigationBlock>
    )
  }
}
// @ts-ignore
export default withRouter(Navbar);