// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
export const configJSON = require("./config");
export const configJSONDashboard = require("../../dashboard/src/config");
import { sendAPIRequest } from "../../../components/src/utils";
import { Message } from "../../../framework/src/Message";
import { RewardData } from "./types"
// Customizable Area End

export interface Props {
  history: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  pointsEarnValue: number;
  rewardValue: number;
  breadCrumb: {}[];
  rewardContent: {
    name: string;
    desc: string;
    description: string;
    attributes: { desc: string; status: string; description: string; earnings: {}[]; sub_categories:{category_name:string [];category_id:number[]; sub_category: {id: number,name: string}}[]; claims: {data: {attributes: {account_id: number, created_at: string}}[]}  };
  };
  approveRewards: { name: string; value: string }[];
  redeemNow: boolean;
  youWillEarn: { name: string; value: string }[];
  dialog: boolean;
  reedem: boolean | undefined;
  contactUsNav: boolean;
  token: string;
  rewardValueData: RewardData[];
  redeembtn: boolean;
  breadCrumData: {}[];
  solution: {}[];
  contact_us: any;
  totalReward: number;
  bandRange: string;
  minRewardVal: number;
  // Customizable Area End
}

interface SS {
  //id: any;
}

export default class RewardsController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getSolutionRewardsPoints: string = "";
  patchRedeemStatus: string = "";
  getContactUs: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      pointsEarnValue: 40,
      rewardValue: 50,
      breadCrumb: [],
      rewardContent: {
        name: "",
        desc: "",
        description: "",
        attributes: { desc: "", status: "", description: "", earnings: [], sub_categories: [{category_name: [],category_id: [], sub_category: {id: 0, name: ""}}], claims: {data: [{attributes: {account_id: 0, created_at: ""}}]}  },
      },
      approveRewards: [{ name: "", value: "" }],
      redeemNow: true,
      youWillEarn: [
        { name: "Carbon Impact ", value: "15 kg CO2e" },
        { name: "Provider discount", value: "30%" },
        { name: "Government grant", value: "$800" },
        { name: "Insurance reduction", value: "20%" },
      ],
      dialog: false,
      reedem: false,
      contactUsNav: false,
      token: "",
      rewardValueData:[],
      redeembtn: false,
      breadCrumData: [],
      solution: [],
      contact_us:{},
      totalReward: 0,
      bandRange: "",
      minRewardVal: 0
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  // Customizable Area Start

  async componentDidMount() {
    super.componentDidMount();
    const token =
      (typeof localStorage !== "undefined" &&
        localStorage.getItem("user_token")) ||
      "";
    this.setState({ token: token,breadCrumData: this.props.history.location.state.breadCrum });
    this.handlCardData();
    this.SolutionRewardsPoints(token);
    this.handleContactUs();
    // Access the data from the location state
    // Use the data as needed
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (apiRequestCallId && responseJson) {
      this.callAfterApiCall(apiRequestCallId, responseJson);
    }
    // Customizable Area Start
    // Customizable Area End
  }

  callAfterApiCall = (apiRequestCallId: string, responseJson: any) => {
    if (apiRequestCallId === this.getSolutionRewardsPoints) {
      if (!responseJson.error) {
        this.setState({totalReward: responseJson?.total_rewards, bandRange: responseJson?.minimum_reward_band, minRewardVal:responseJson?.minimum_reward_value })
        if(this.state.totalReward > 0 && this.state.totalReward >= this.state.minRewardVal){
          this.setState({redeembtn: false})
        }else{
          this.setState({redeembtn: true})
        }
        return responseJson;
      } else {
        this.parseApiErrorResponse(responseJson);
      }
    }
    if (apiRequestCallId === this.getContactUs ) {
      if (!responseJson.error) {
        const contactus = responseJson.filter((data: any) => {
          return data.title === "Contact Us";
        });
        this.setState({ contact_us: contactus[0].description });
        return responseJson;
      } 
    }
  };

  handlCardData = () => {
    const breadData = this.props.history.location.state.breadCrum;
    const isReedem = this.props.history.location.state.redeem;
    
    this.setState({
      
      breadCrumb: breadData,
      rewardContent: breadData[1],
      reedem: isReedem,
    });
  };

  handleClickRedeemNow = () => {
    this.setState({ redeemNow: false, redeembtn:true });
    this.updateRedeemStatus();
  };

  handleCloseModel = () => {
    this.setState({ dialog: false });
  };

  handleReportProgress = () => {
    this.props.history.push({
      pathname: "Details",
      state: {
        breadCrum: [
          { name: "Focus area", path: "Categories" },
          { name: this.state.rewardContent.attributes.sub_categories[0].category_name[0], path: "SubCategories", id: this.state.rewardContent.attributes.sub_categories[0].category_id[0]},
          {
            name: this.state.rewardContent.attributes.sub_categories[0].sub_category.name,
            path: "Details",
            attributes: {...this.state.rewardContent.attributes,solution_description:this.state.rewardContent.attributes.description},
          },
        ],
         headerText: this.state.rewardContent.attributes.sub_categories[0].category_name[0],
         account_id: this.state.rewardContent.attributes.claims.data[0].attributes.account_id
      },
    });
  };

  handleContactUsClick = (width: number) => {
    if (width < 600) {
      this.setState({ contactUsNav: true })
    } else {
      this.setState({ dialog: true })
    }
  }

  SolutionRewardsPoints = (token: string) => {
    this.getSolutionRewardsPoints = sendAPIRequest(configJSON.getSolutionRewardPoint, {
      headers: { token: token, "Content-Type": "application/json" },
    });
  }

  updateRedeemStatus = () => {
    this.patchRedeemStatus = sendAPIRequest(configJSON.pachRedeemStatus, {
      headers: { token: this.state.token, "Content-Type": "application/json"},
      method: "PATCH",
      body: {}
    }); 
  }

  handleContactUs = () => {
    this.getContactUs = sendAPIRequest(configJSON.termsAndCondition);
  }
  // Customizable Area End
}
