// Customizable Area Start
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible, Ecounaware, EcoConscious, EcoActive } from "./assets";
import MuiAlert from "@material-ui/lab/Alert";
import React from "react";
interface ChangeEvent {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  reTypePassword: string;
  target: Object;
  termsCondition: boolean;
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  // Customizable Area Start
  navigation: any;
  id: string;
  history: any;
  // Customizable Area End
}

export interface S {
  // Customizable Area Start
  firstName: string;
  firstNameError: string;
  lastName: string;
  lastNameError: string;
  email: string;
  emailError: string;
  password: string;
  passwordError: string;
  authToken: string;
  reTypePassword: string;
  reTypePasswordError: string;
  data: any[];
  device_id: string;
  unique_auth_id: string;
  passwordHelperText: string;
  enablePasswordField: boolean;
  enableReTypePasswordField: boolean;
  countryCodeSelected: string;
  phone: string;
  errors: string;
  errorN: boolean;
  errorN1: boolean;
  errorN2: boolean;
  errorN3: boolean;
  errorN4: boolean;
  popupMessage: boolean;
  responseData: string;
  errStatus: boolean;
  termsCondition: boolean;
  apiSuccess: boolean;
  type: string;
  dialog: boolean;
  userData: {name: string, type: string, value: string, img: any}[];
  mobileUsertype: boolean;
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class EmailAccountRegistrationController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  arrayholder: any[];
  passwordReg: RegExp;
  emailReg: RegExp;
  nameReg: RegExp;
  createAccountApiCallId: string = "";
  getEmailVerificationApiCallId: string = "";
  validationApiCallId: string = "";

  imgPasswordVisible: any;
  imgPasswordInVisible: any;

  labelHeader: any;
  labelFirstName: string;
  lastName: string;
  labelEmail: string;
  labelPassword: string;
  labelRePassword: string;
  labelLegalText: string;
  labelLegalTermCondition: string;
  labelLegalPrivacyPolicy: string;
  btnTextSignUp: string;
  currentCountryCode: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];
    this.receive = this.receive.bind(this);
    this.isStringNullOrBlank = this.isStringNullOrBlank.bind(this);

    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {
      // Customizable Area Start
      firstName: "",
      firstNameError: "",
      lastName: "",
      lastNameError: "",
      email: "",
      emailError: "",
      password: "",
      passwordError: "",
      reTypePassword: "",
      reTypePasswordError: "",
      authToken: "",
      data: [],
      device_id: "",
      unique_auth_id: "",
      passwordHelperText: "",
      enablePasswordField: true,
      enableReTypePasswordField: true,
      countryCodeSelected: "",
      phone: "",
      errors: "",
      errorN: false,
      errorN1: false,
      errorN2: false,
      errorN3: false,
      errorN4: false,
      popupMessage: false,
      responseData: "",
      errStatus: false,
      termsCondition: false,
      apiSuccess: false,
      type: "",
      dialog: false,
      userData: [
        {
          name: "Eco - unaware",
          type: "unsure",
          value: "Eco_Unaware",
          img: Ecounaware
        },
        {
          name: "Eco - Conscious",
          type: "Knowledgeable",
          value: "Eco_Conscious",
          img: EcoConscious
        },
        {
          name: "Eco - Active",
          type: "Enthusiastic",
          value: "Eco_Active",
          img: EcoActive
        },
      ],
      mobileUsertype: false,
      // Customizable Area End
    };

    // Customizable Area Start
    this.arrayholder = [];
    this.passwordReg = new RegExp(configJSON.passwordRegs);
    this.emailReg = new RegExp(configJSON.emailRegs);
    this.nameReg = new RegExp(configJSON.nameRegs);
    this.passwordReg = new RegExp(configJSON.passwordRegs);

    this.imgPasswordVisible = imgPasswordVisible;
    this.imgPasswordInVisible = imgPasswordInVisible;

    this.labelHeader = configJSON.labelHeader;
    this.labelFirstName = configJSON.labelFirstName;
    this.lastName = configJSON.lastName;
    this.labelEmail = configJSON.labelEmail;
    this.labelPassword = configJSON.labelPassword;
    this.labelRePassword = configJSON.labelRePassword;
    this.labelLegalText = configJSON.labelLegalText;
    this.labelLegalTermCondition = configJSON.labelLegalTermCondition;
    this.labelLegalPrivacyPolicy = configJSON.labelLegalPrivacyPolicy;
    this.btnTextSignUp = configJSON.btnTextSignUp;
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start

    this.createUserAccountResponse(from, message);
    //this.emailVerificationResponse(from, message);
    // Customizable Area End
  }

  // Customizable Area Start

  async componentDidMount() {
    this.backNavigation();
  }

  backNavigation = () => {
    const token = localStorage.getItem("user_token");
    const logout = localStorage.getItem("Logout");
    if (token !== null && logout === 'false') {
      this.props.history.push("/EngagementUser")
    }
  }

  createUserAccountResponse = (from: string, message: Message) => {
    if (
      this.createAccountApiCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      const apiErrorResponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiResponse) {
        if (apiResponse.errors) {
          const error = this.handleErrorMessage(apiResponse.errors);
          this.setState({
            popupMessage: true,
            responseData: error,
            firstName: "",
            lastName: "",
            email: "",
            password: "",
            reTypePassword: "",
            errStatus: true,
            apiSuccess: true
          });
        } else {
          this.setState({ authToken: apiResponse.meta.token });
          this.setState({
          popupMessage: true,
          apiSuccess: true,
          errStatus: false,
          responseData: apiResponse.message})
          setTimeout(() => {
            global.localStorage.setItem(
              "walkthrough_token",
              this.state.authToken
            );
            this.handleusernavigation()
          }, 1000);
        }
      } else if (apiErrorResponse) {
        this.setState({
          popupMessage: true,
          responseData: apiResponse.errors[0].account,
          firstName: "",
          lastName: "",
          email: "",
          password: "",
          reTypePassword: "",
          errStatus: true,
          apiSuccess: false
        });
      }
    }
  };
  handleusernavigation(){
    if(this.state.type=="employee")
    {this.props.history.push("/Walkthrough", this.state.authToken);}
   else{
    this.props.history.push("/")
   }
}

  goToTermsAndCondition() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationTermAndConditionMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  isStringNullOrBlank(str: string) {
    return str === null || str.length === 0;
  }

  handleCloseSnackBar = () => {
    this.setState({ popupMessage: false, responseData: "" });
  };

  handleCloseSnackBarForTC = () => {
    this.setState({ popupMessage: false, termsCondition: false });
  };

  createAccount = (width: boolean) => {
    if (this.state.firstName === "") {
      this.setState({ firstNameError: configJSON.errorFirstNameRequired });
      this.setState({ errorN: true });
    }
    if (this.state.lastName === "") {
      this.setState({ lastNameError: configJSON.errorLastNameRequired });
      this.setState({ errorN1: true });
    }
    if (this.state.email === "") {
      this.setState({ emailError: configJSON.errorEmailRequired });
      this.setState({ errorN2: true });
    }
    if (this.state.password === "") {
      this.setState({ passwordError: configJSON.errorPasswordRequired });
      this.setState({ errorN3: true });
    }
    if (this.state.reTypePassword === "") {
      this.setState({ reTypePasswordError: configJSON.errorPasswordMatch });
      this.setState({ errorN4: true });
    }
    if (this.state.password !== this.state.reTypePassword) {
      this.setState({ reTypePasswordError: configJSON.errorPasswordMatch });
      this.setState({ errorN4: true });
    }
    if(this.state.firstNameError === "" && this.state.lastNameError === "" && this.state.emailError === "" && this.state.passwordError === ""  && this.state.reTypePasswordError === ""&& this.state.type !== "" && this.state.termsCondition && this.state.password === this.state.reTypePassword){
     return this.handleOpenModal(width);
    }
    if (
      this.state.firstName === "" ||
      this.state.lastName === "" ||
      this.state.email === "" ||this.state.reTypePassword === "" ||
      this.state.password !== this.state.reTypePassword ||
      !this.state.termsCondition
    ) {
      this.setState({ popupMessage: true });
      return false;
    }
    return true;
  };

  handleUserType = (value: string) => {
    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail
    };

    const attrs = {
      first_name: this.state.firstName,
      last_name: this.state.lastName,
      email: this.state.email,
      password: this.state.password,
      user_name: `${this.state.firstName}${this.state.lastName}`,
      profile_type: value,
      user_role: this.state.type,
      approve: "false",
      reward_percentage: this.state.type === "employer" ? "100" : "0",
    };
    localStorage.setItem('profielType' ,value)

    const data = {
      type: "email_account",
      attributes: attrs
    };

    const body = {
      data: data
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.createAccountApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.accountsAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    this.setState({dialog: false})
  }

  getValidations() {

    const header = {
      "Content-Type": configJSON.validationApiContentType
    };

    const getValidationMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.validationApiCallId = getValidationMsg.messageId;

    getValidationMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.urlGetValidations
    );

    getValidationMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    getValidationMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(getValidationMsg.id, getValidationMsg);
  }

  imgEnableRePasswordFieldProps = {
    source: imgPasswordVisible
  };

  enablePasswordShow = () => {
    this.setState({ enablePasswordField: !this.state.enablePasswordField });
  };

  enableConfirmPasswordShow = () => {
    this.setState({
      enableReTypePasswordField: !this.state.enableReTypePasswordField
    });
  };

  changePasswordType = () => {
    if (this.state.enablePasswordField) {
      return "password";
    } else {
      return "text";
    }
  };

  changeConfirmPasswordType = () => {
    if (this.state.enableReTypePasswordField) {
      return "password";
    } else {
      return "text";
    }
  };

  Alert(props: any) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  myFunction = () => {
    this.enablePasswordShow();
    this.enableConfirmPasswordShow();
    this.Alert(this.props);
    this.goToTermsAndCondition();
    this.handleCloseSnackBar();
    this.handleCloseSnackBarForTC();
  };

  imgEnablePasswordFieldProps = {
    source: imgPasswordVisible
  };

  btnPasswordShowHideProps = {
    onPress: () => {
      this.setState({ enablePasswordField: !this.state.enablePasswordField });
      this.txtInputPasswordProps.secureTextEntry = !this.state
        .enablePasswordField;
      this.imgEnablePasswordFieldProps.source = this.txtInputPasswordProps
        .secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    }
  };

  txtInputEmailWebPrpos = {};

  txtInputEmailMobilePrpos = {
    ...this.txtInputEmailWebPrpos,
    keyboardType: "email-address"
  };

  txtPhoneNumberWebProps = {};

  txtPhoneNumberMobileProps = {
    ...this.txtPhoneNumberWebProps,
    autoCompleteType: "tel",
    keyboardType: "phone-pad"
  };

  txtInputLastNamePrpos = {
    onChangeText: (text: string) => {
      this.setState({ lastName: text });

      //@ts-ignore
      this.txtInputLastNamePrpos.value = text;
    }
  };

  txtInputConfirmPasswordProps = {
    onChangeText: (text: string) => {
      this.setState({ reTypePassword: text });

      //@ts-ignore
      this.txtInputConfirmPasswordProps.value = text;
    },
    secureTextEntry: true
  };

  txtInputPasswordProps = {
    onChangeText: (text: string) => {
      this.setState({ password: text });

      //@ts-ignore
      this.txtInputPasswordProps.value = text;
    },
    secureTextEntry: true
  };

  handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ termsCondition: event.target.checked });
  };

  handleChangefirstName = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const { value } = event.target;
    this.setState({ firstName: event.target.value });
    if (value === "") {
      this.setState({
        firstNameError: configJSON.errorFirstNameRequired,
        errorN: true
      });
    } else if (!this.nameReg.test(event.target.value)) {
      this.setState({
        firstNameError: configJSON.errorFirstNameValid,
        errorN1: false
      });
    } else {
      this.setState({ firstNameError: "", errorN: false });
    }
  };
  handleChangelastName = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ lastName: event.target.value });
    if (event.target.value === "") {
      this.setState({
        lastNameError: configJSON.errorLastNameRequired,
        errorN1: true
      });
    } else if (!this.nameReg.test(event.target.value)) {
      this.setState({
        lastNameError: configJSON.errorLastNameValid,
        errorN1: false
      });
    } else {
      this.setState({ lastNameError: "", errorN1: false });
    }
  };
  handleChangeemail = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ email: event.target.value });
    if (event.target.value === "") {
      this.setState({ emailError: configJSON.errorEmailRequired });
      this.setState({ errorN2: true });
    } else if (!this.emailReg.test(event.target.value)) {
      this.setState({ emailError: configJSON.errorEmailNotValid });
      this.setState({ errorN2: true });
    } else {
      this.setState({ emailError: "" });
      this.setState({ errorN2: false });
    }
  };

  handleChangePassword = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ password: event.target.value });
    if (event.target.value === "") {
      this.setState({ passwordError: configJSON.errorPasswordRequired });
      this.setState({ errorN3: true });
    } else if (!this.passwordReg.test(event.target.value)) {
      this.setState({ passwordError: configJSON.errorPasswordFormat });
      this.setState({ errorN3: true });
    } else {
      this.setState({ passwordError: "" });
      this.setState({ errorN3: false });
    }
  };

  handleChangeRepassword = (event: any) => {
    this.setState({ reTypePassword: event.target.value });
    if (event.target.value === this.state.password) {
      this.setState({ reTypePasswordError: "" });
      this.setState({ errorN4: false });
    } else {
      this.setState({ reTypePasswordError: configJSON.errorPasswordMatch });
      this.setState({ errorN4: true });
    }
  };

  handleErrorMessage = (error: any) => {
    const array = Array.isArray(error);
    if (array) {
      return error[0].account;
    } else {
      return error;
    }
  };

  handleTypeSelect = (event: any) => {
    this.setState({ type: event.target.value });
  };

  handleCloseModel = () => {
    this.setState({dialog: false})
  }

  handleOpenModal = (value: boolean) => {
    this.setState({dialog:true,mobileUsertype:value})
  }

  handleNavigateTermsAndCondition = () => {
    this.props.history.push({pathname: "TermsAndCondition"});
  }

  // Customizable Area End
}
// Customizable Area End