import React from "react";

// Customizable Area Start
import { Box, Typography, Button, Divider, Dialog } from "@material-ui/core";
import RewardsSubController, { Props, configJSON } from "./RewardsSubController.web";
import Navbar from "../../../components/src/Navbar.web";
import { styled } from "@material-ui/styles";
import { cubeImage, yellowIcon, big_star, star } from "./assets";
import BreadCrumb from "../../../components/src/BreadCrumb.web";
import { RewardData } from "./types"

// Customizable Area End

//Customizable Area Start

const PayoutWraper = styled(Box)({
  width: "100%",
  textAlign: "center",
  "& .approve-box": {
    width: "25%",
    margin: "0 auto",
    "@media (max-width:600px)": {
      width: "85%",
    },
    "@media (min-width:600px)": {
      width: "64%",
    },
    "@media (min-width:720px)": {
      width: "52%",
    },
    "@media (min-width:980px)": {
      width: "38%",
    },
    "@media (min-width:1460px)": {
      width: "31%",
    },
  },
  "& .earn-title": {
    position: "relative",
    marginTop: "33px",
    fontSize: "20px",
    fontWeight: 700,
    lineHeight: "24px",
    color: "#FAFAFA",
    "@media (max-width:600px)": {
      fontSize: "16px",
      fontWeight: 700,
      lineHeight: "24px",
      textAlign: "left",
      width: "80%",
    },
  },
  "& .star-logo": {
    width: "30px",
    position: "absolute",
    top: "-20px",
    "@media (max-width:600px)": {
      right: "0px",
    },
  },
  "& .reward-main-box": {
    backgroundColor: "#2d253d",
    borderRadius: "24px",
    padding: "24px",
    marginTop: "24px",
    color: "#FAFAFA",
  },
  "& .reward-box": {
    gap: "23px",
    display: "flex",
    justifyContent: "space-between",
    padding: "10px",
  },
  "& .band-message": {
    marginTop:"21px"
  },
  "& .reward-title": {
    textAlign: "start"
  },
  "& .dividercontent": {
    backgroundColor: "rgba(250, 250, 250, 0.12)",
    margin: "10px 10px",
  },
  "& .submit-btn": {
    width: "100%",
    backgroundColor: "yellow",
    borderRadius: "17px 2px",
    padding: "8px",
    textTransform: "none",
    letterSpacing: "1px",
    fontSize: "16px",
    color: "#1B122E",
    fontWeight: 700,
    fontFamily: "Figtree, sans-serif",
    marginTop: "40px",
  },
  "& .disable-submit-btn": {
    backgroundColor:"#ecef518a"
  },
  "& .description": {
    fontFamily: "Figtree, sans-serif",
    marginTop: "24px",
    color: "#FAFAFACC",
    textAlign: "left",
    "@media (max-width:600px)": {
      fontSize: "13px",
      fontWeight: 400,
      marginTop: "106px",
      lineHeight: "20px",
      fontFamily: "Figtree, sans-serif",
      textAlign: "center"
    },
  },
});

const Model = styled(Box)({
  backgroundColor: "#1B122E",
  height: "auto",
  "@media (min-height: 900px)": {
    height: "100vh",
  },
  "& .main-content": {
    margin: "55px auto 0px",
    width: "90%",
    "@media (max-width: 600px)": {
      display: "none",
    },
  },
  "& .approved-box": {
    borderRadius: "24px",
    border: "1px solid rgba(250, 250, 250, 0.38)",
    padding: "16px",
    display: "none",
    "@media (max-width:600px)": {
      display: "block",
    },
  },
  "& .approved-box-title": {
    display: " flex ",
    justifyContent: "space-between",
    marginBottom: "4px",
  },
  "& .approved-box-desc": {
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "16px",
    color: "#FAFAFACC",
  },
  "& .redeem-btn": {
    width: "100%",
    borderRadius: "24px",
    marginTop: "18px",
    color: "#1B122E",
    backgroundColor: "#ECEF51",
    fontSize: "14px",
    fontWeight: 700,
    lineHeight: "20px",
    textTransform: "unset",
  },
  "& .redeem-large": {
    width: "100%",
    borderRadius: "24px",
    marginTop: "18px",
    color: "#FAFAFA",
    fontSize: "14px",
    fontWeight: 700,
    lineHeight: "20px",
    textTransform: "unset",
    border: "1px solid #FAFAFACC",
    "@media (max-width:600px)": {
      display: "none",
    },
  },
  "& .approved-desc": {
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
    color: "#FAFAFACC",
    "@media (max-width:600px)": {
      display: "none",
    },
  },
});

const Wrapper = styled(Box)({
  backgroundColor: "#1B122E",
  color: "rgb(250 250 250 )",
  "& .cube-img": {
    width: 23,
  },
  "& .heading-text": {
    fontSize: "24px",
    fontWeight: 700,
    lineHeight: "32px",
    fontFamily: "Figtree, sans-serif",
    color: "#FAFAFACC",
    "@media (max-width:600px)": {
      fontSize: "13px",
      fontWeight: 500,
      lineHeight: "20px",
      fontFamily: "Figtree, sans-serif",
    },
  },
  "& .list1": {
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "24px",
    "@media (max-width:600px)": {
      fontSize: "14px",
      lineHeight: "24px",
    },
  },
  "& .list2": {
    fontFamily: "Figtree, sans-serif",
    fontWeight: 700,
    fontSize: "14px",
    lineHeight: "24px",
    "@media (max-width:600px)": {
      fontSize: "14px",
      lineHeight: "24px",
    },
  },
  "& .submit-btn": {
    width: "100%",
    backgroundColor: "yellow",
    borderRadius: "17px 2px",
    padding: "8px",
    textTransform: "none",
    letterSpacing: "1px",
    fontSize: "16px",
    color: "#1B122E",
    fontWeight: 700,
    fontFamily: "Figtree, sans-serif",
  },
  "& .description": {
    fontFamily: "Figtree, sans-serif",
    marginTop: "24px",
    color: "#FAFAFACC",
    "@media (max-width:600px)": {
      fontSize: "13px",
      fontWeight: 400,
      marginTop: "16px",
      lineHeight: "20px",
      fontFamily: "Figtree, sans-serif",
    },
  },
  "& .status": {
    fontFamily: "Figtree, sans-serif",
    color: "#FAFAFA",
    marginTop: "35px",
    fontSize: "16px",
    fontWeight: 700,
    "@media (max-width:600px)": {
      marginTop: "0px",
    },
  },
  "& .star-logo": {
    width: "18px",
    position: "absolute",
    top: "-6px",
  },
  "& .read-more": {
    color: "#67ADFF",
    "@media (max-width:600px)": {
      fontSize: "12px",
      fontWeight: 500,
      lineHeight: "24px",
      fontFamily: "Figtree, sans-serif",
    },
  },
  "& .earn-title": {
    position: "relative",
    fontFamily: "Figtree, sans-serif",
    marginTop: "33px",
    fontSize: "20px",
    fontWeight: 700,
    lineHeight: "24px",
    "@media (max-width:600px)": {
      fontSize: "16px",
      fontWeight: 700,
      lineHeight: "24px",
      fontFamily: "Figtree, sans-serif",
    },
  },
  "& .font-family": {
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "24px",
    fontFamily: "Figtree, sans-serif",
  },
  "& .buttoncontent": {
    fontFamily: "Figtree, sans-serif",
    textTransform: "unset",
    fontSize: "16px",
    fontWeight: 700,
    borderRadius: "25px",
    padding: "5px 45px",
    height: "40px",
    border: "2px solid #FAFAFAb0",
    color: "#FAFAFA",
    position: "unset",
    width: "327px",
    "@media (max-width:600px)": {
      width: "100%",
    },
  },
  "& .button-main-content": {
    marginTop: "60px",
    width: "fit-content",
    "@media (max-width:600px)": {
      width: "100%",
    },
  },
  "& .or-text": {
    textAlign: "center",
    padding: "16px 0",
  },
  "& .mobile-title": {
    padding: "42px 0 26px",
    display: "flex",
    "@media (max-width:600px)": {
      alignItems: "center",
      padding: "10px 0 10px",
    },
  },
  "& .imageContent": {
    display: "none",
    padding: "6px 10px 10px 0px",
    "@media (max-width:600px)": {
      display: "inline-block",
    },
  },
  "& .change-now-box": {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
    backgroundColor: "#3a324b",
    width: "140px",
    padding: "8px",
    borderRadius: "0 10px 10px 0",
    borderLeft: " 4px solid #FF9520",
    "@media (max-width:600px)": {
      display: "none",
    },
  },
});

const ModelWrapper = styled(Dialog)({
  "& .message-text": {
    fontSize: "16px",
    fontWeight: 400,
    padding: "7px 37px 33px 4px",
    "@media (max-width:600px)": {
      fontSize: "14px",
      fontWeight: 400,
      margin: "10px 0px",
    },
  },
  "& .model-title": {
    fontSize: "18px",
    fontWeight: 700,
    paddingBottom: "5px",
    "@media (max-width:600px)": {
      fontSize: "16px",
      fontWeight: 700,
    },
  },
  "& .setting-box": {
    display: "flex",
    justifyContent: "space-between",
  },
});

const ContactStyle = styled('div')({
  '& p': {
      '& a': {
          color: '#67ADFF'
      }
  }
})
//Customizable Area End

export default class RewardsSub extends RewardsSubController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  StatusColor(type: string) {
    let color;
    if (type === "Approved") {
      color = "#208C8C";
    } else if (type === "Not Approved") {
      color = "#EA7D7D";
    } else {
      color = "#FF9520";
    }
    return (
      <Typography
        variant="body1"
        style={{ color: color, display: "inline-block", fontWeight: 700 }}
      >
        {type}
      </Typography>
    );
  }

  renderSettingModel = () => {
    return (
      <ModelWrapper
        open={this.state.dialog}
        onClose={this.handleCloseModel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          style: {
            backgroundColor: "#2d253e",
            borderRadius: "24px",
            padding: "24px 25px",
            color: "#fff",
            width: "315px"
          },
        }}
      >
        <Box className="report-modal">
          <Typography variant="body1" className="model-title">
            Contact Us
          </Typography>
          <Box className="setting-box">
            <ContactStyle>
              {this.state.contact_us.length ? (<Typography variant="body2" className="message-text" dangerouslySetInnerHTML={{ __html: this.state.contact_us }} />) : (<Typography className="no-data-found">No Data available</Typography>)}
              {/* <Typography variant="body2" className="message-text" dangerouslySetInnerHTML={{ __html: this.state.contactUsStoreData }} /> */}
            </ContactStyle>  
          </Box>
        </Box>
      </ModelWrapper>
    );
  };

  redeem = (redeemNow: any) => {
    return(
      redeemNow ? (
        <Button
          variant="contained"
          className={ this.state.redeembtn ? "submit-btn disable-submit-btn":"submit-btn"}
          data-test-id="open-upload-dialog"
          onClick={this.handleClickRedeemNow}
          disabled={this.state.redeembtn}
        >
          Redeem now
        </Button>
      ) : (
        <Typography variant="body1" className="description">
          Thank you! Your redemption request has been submitted. It
          should be processed in 3-5 days.
        </Typography>
      )
    )
  }

  render() {
    const { breadCrumb, rewardContent, redeemNow, reedem, rewardValueData } = this.state;
    const rewardStatus = rewardContent.attributes?.status
    const originalDate = new Date(rewardContent?.attributes?.claims.data[0]?.attributes?.created_at);
    const dateData = originalDate.getDate();
    const monthData = originalDate.toLocaleString('default', { month: 'long' })
    const yearData = originalDate.getFullYear();
    const hours = originalDate.getUTCHours();
    const minutes = originalDate.getUTCMinutes();
    const formattedTime = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
    
    return (
      // Required for all blocks
      <Model>
        {this.renderSettingModel()}
        <Navbar
          // @ts-ignore
          params={"Rewards"}
          headerText={reedem ? "Payout" : "RewardsStatus"}
          history={this.props.history}
          rewardContactUs={this.state.contactUsNav}
          contact_us={this.state.contact_us}
        />
        <Box className="main-content">
          <BreadCrumb
            params={""}
            history={this.props.history}
            breadcrumData={breadCrumb}
          />
        </Box>
        {reedem ? (
          <PayoutWraper>
            <Box className="approve-box">
              <Typography variant="h5" className="earn-title">
                Use points & rewards with Ezeo partners
                <img className="star-logo" src={big_star} alt="star" />
              </Typography>
              <Box className="reward-main-box">
                {this.state.totalReward > 0 ? (
                  <>
                    <Box className="reward-box">
                      <Typography className="reward-title">
                          Redeemable reward value
                      </Typography>
                      <Typography className="reward-value">
                          ${this.state.totalReward}
                      </Typography>
                    </Box> 
                    {this.state.totalReward < this.state.minRewardVal ? (
                      <>
                      <Divider
                        className="dividercontent"
                        variant="middle"
                      />
                      <Typography className="band-message">{this.state.bandRange}</Typography>
                      </>
                    ) : ""}
                  </>     
                ) : <Typography> No rewards avaliable</Typography>}
              </Box>
              {this.redeem(redeemNow)}
            </Box>
          </PayoutWraper>
        ) : (
          <Wrapper
            sx={{ width: "90%", margin: "0px auto", marginBottom: "70px" }}
          >
            <Box className="mobile-title">
              <img src={yellowIcon} className="imageContent" />
              <Typography className="heading-text" variant="h5">
                {rewardContent.name}
              </Typography>
            </Box>
            <Box className="change-now-box">
              <img src={cubeImage} alt="cubeImg" className="cube-img" />
              <Typography variant="body1" className="font-family">
                {configJSON.Changenow}
              </Typography>
            </Box>
            {rewardStatus === "Approved" && (
              <Typography
                className="approved-desc"
                style={{ marginTop: "32px" }}
              >
                {rewardContent.attributes.description}
              </Typography>
            )}
            <Typography variant="body1" className="status">
              Status : {this.StatusColor(rewardContent.attributes.status)}
            </Typography>
            {rewardStatus !== "Approved" ? (
              <Typography variant="body1" className="description">
                {rewardContent.attributes.desc}
              </Typography>
            ) : (
              <>
                <Typography variant="h5" className="earn-title">
                  You earned
                  <img className="star-logo" src={star} alt="star" />
                </Typography>
                <Box
                  sx={{
                    width: { xs: "100%", sm: "80%", md: "40%", lg: "30%" },
                  }}
                >           
                  {rewardContent.attributes.earnings && rewardContent.attributes.earnings.length > 0 && (
                    <>
                      {rewardContent.attributes.earnings.map((item: any) => (
                        <Box
                          key={item.name}
                          display="flex"
                          justifyContent="space-between"
                          sx={{ margin: "30px 0" }}
                        >
                          <Typography variant="body1" className="list1">
                            {item.title}
                          </Typography>
                          <Typography variant="body1" className="list2">
                            {item.value}
                          </Typography>
                        </Box>
                      ))}
                      <Typography className="approved-desc">
                        {`You reported progress on ${dateData} ${monthData} ${yearData} 
                        at ${formattedTime} and it is
                        approved. Please check the rewards page to know the status
                        and claim your reward.`}
                      </Typography>
                      <Button
                        className="redeem-large"
                        data-test-id="large-redeem-btn"
                        onClick={() => this.setState({ reedem: true })}
                      >
                        Redeem
                      </Button>
                    </>
                  )}
                </Box>
              </>
            )}
            {rewardStatus !== "Approved" ? (
              <Box className="button-main-content">
                <Button
                  className="buttoncontent"
                  variant="outlined"
                  data-test-id="details-btn"
                  onClick={this.handleReportProgress}
                >
                  Report progress
                </Button>
                <Typography className="or-text">Or</Typography>
                <Button
                  className="buttoncontent"
                  variant="outlined"
                  data-test-id="details-btn-contact"
                  onClick={() => this.handleContactUsClick(window.innerWidth)}
                >
                  Contact Ezeo support
                </Button>
              </Box>
            ) : (
              <Box className="approved-box">
                <Box className="approved-box-title">
                  <Typography variant="body1" className="status">
                    Ezeo vouchers
                  </Typography>
                  <Typography variant="body1" className="list2">
                    100$
                  </Typography>
                </Box>
                <Typography className="approved-box-desc">
                  Voucher details -Lorem ipsum dolor sit amet, consectetur
                  adipiscing elit. Morbi sollicitudin tellus quis nunc
                  posuerefinibus sem.
                </Typography>
                <Button className="redeem-btn" data-test-id="reedem-button" onClick={() => this.setState({ reedem: true })}>Redeem</Button>
              </Box>
            )}
          </Wrapper>
        )}
      </Model>
    );
  }
}
