// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
export const configJSON = require("./config");
import { sendAPIRequest } from "../../../components/src/utils";
import { Message } from "../../../framework/src/Message";
import * as Yup from "yup";
// Customizable Area End

export interface Props {
  history: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  breadCrumData: {
    attributes: {id:number};
    name: string;
    path: string;
  }[];
  readMore: boolean;
  openReportModel: boolean;
  files: ({ name: string; file: File,solution_id:string } | undefined)[];
  openSucessModal: boolean;
  openRewardForm: boolean;
  lastHeading: string;
  details: {
    ezeo_reward_points: string;
    id: number;
    solution_description: string;
    solution_title: string;
    solution_horizon: string;
    earnings: {
      id: number;
      title: string;
      value: string;
      solution_id: number;
      created_at: string;
      updated_at: string;
    }[];
  };
  token: string | null;
  additionalQuestion: { id: number; question: string; solution_id: number }[];
  formData: {};
  buttonText: string;
  schema: any;
  answers: { question_id: string; answer: string }[];
  solution_id: string;
  zipFile:any;
  errorMsg: boolean;
  accountId: string | number;
  commitToSolutionBtn: boolean;
  questionId: number[];
  submitBtn: boolean;
  redirectDetailPage:boolean;
  uploadMessage: string;
  loaderData: boolean;
  commitSucessMsg: boolean;
  contact_us: any;
  // Customizable Area End
}

interface SS {
  //id: any;
}

interface ClaimsData {
  id: string;
  attributes: {
    status: string;
  };
}
interface ClaimsAllData {
  id: string;
  attributes: {
    claims: {
      data: ClaimsData[];
    };
  };
}


export default class DetailsController extends BlockComponent<Props, S, SS> {
  getAdditionalQuestions: string = "";
  postAdditionalQuestions: string = "";
  postClaimAPI:string = "";
  commitToSolution:string = "";
  getClaimStatusApi:string = "";
  getSolutionApi:string = "";
  postCreateCommits:string = "";
  getContactUs: string = "";
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage)
    ];

    this.state = {
      breadCrumData: [],
      readMore: false,
      openReportModel: false,
      files: [],
      lastHeading: "",
      openSucessModal: false,
      details: {
        ezeo_reward_points: "",
        id: 0,
        solution_description: "",
        solution_title: "",
        solution_horizon: "",
        earnings: []
      },
      openRewardForm: false,
      token: "",
      additionalQuestion: [],
      formData: {},
      buttonText: configJSON.commit_to_solution,
      schema: {},
      answers: [],
      solution_id: "",
      zipFile: [],
      errorMsg: false,
      accountId: 0,
      commitToSolutionBtn: false,
      questionId: [],
      submitBtn: false,
      redirectDetailPage:false,
      uploadMessage: "",
      loaderData: false,
      commitSucessMsg: false,
      contact_us:{}
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    await super.componentDidMount();
    const token = (typeof localStorage !== "undefined" && localStorage.getItem('user_token')) || '';
    this.setState({ token: token });
    this.handleBread();
    this.additionalQuestions();
    this.claimStatusDataApi();
    this.handleContactUs();
    const state = this.props.history?.location?.redirectDetailPage;
    this.setState({ buttonText: state ? configJSON.report_progress:configJSON.commit_to_solution });
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    if (apiRequestCallId && responseJson) {
      switch (apiRequestCallId) {
        case this.getAdditionalQuestions:
        this.callAfterApiCall(responseJson);
        break;

      case this.postAdditionalQuestions:
        this.handlePostAdditionalQuestions(responseJson);
        break;

      case this.postClaimAPI:
        this.handlePostClaimAPI(responseJson);
        break;

      case this.commitToSolution:
        this.handleCommitToSolution(responseJson);
        break;


      case this.getClaimStatusApi:
        this.buttonStatus(responseJson);
        break;  

      case this.getSolutionApi:
        this.claimStatusData(responseJson);
        break;
        
      case this.postCreateCommits:
        if(!responseJson.error){
          this.getCreateCommitsol();
        }
        break;
        // return responseJson;
      case this.getContactUs:
        this.contactUsDatas(responseJson)
        return responseJson;
      }
    }
    // Customizable Area Start
    // Customizable Area End
  }
  createCommit = (id: any) => {
    this.postCreateCommits = sendAPIRequest(configJSON.createCommit, {
      headers: { token: this.state.token, "Content-Type": "application/json"},
      method: "POST",
      body: {commit: { solution_id: id}}
    });  
    this.setState({buttonText:configJSON.report_progress})
  }

  getCreateCommitsol = () => {
    this.commitToSolution = sendAPIRequest(`${configJSON.postCommitToSolution}?solution_id=${this.state.solution_id}`, {
      headers: { token: this.state.token, "Content-Type": "application/json"}
    });
  }
  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>): void {
    if (prevState.openRewardForm !== this.state.openRewardForm) {
      this.setState({ lastHeading: this.state.breadCrumData[2].name });
      const data = this.state.breadCrumData;
      data.pop();
      if (this.state.openRewardForm) {
        this.setState({
          breadCrumData: [
            ...data,
            {
              name: "Additional questions",
              path: "Details",
              attributes: this.state.details
            }
          ]
        });
      } else {
        this.setState({
          breadCrumData: [
            ...data,
            {
              name: prevState.lastHeading,
              path: "Details",
              attributes: this.state.details
            }
          ]
        });
      }
    }
    if(prevState.solution_id !== this.state.solution_id){
      this.commitToSolution = sendAPIRequest(`${configJSON.postCommitToSolution}?solution_id=${this.state.solution_id}`, {
        headers: { token: this.state.token, "Content-Type": "application/json"}
      });
    }
  }

  callAfterApiCall = (responseJson: any) => {
    if (!responseJson.errors) {
      const data: { [key: string]: string } = {};
      const yupSchema: { [key: string]: any } = {};
      this.setState({
        additionalQuestion: responseJson,
        solution_id: `${responseJson[0].solution_id}`
      });
      responseJson.map((item: { id: number }) => {
        const id = `${item.id}`;
        data[id] = "";
        yupSchema[id] = Yup.string().required("Required Field*");
      });
      const validation = Yup.object().shape(yupSchema);
      this.setState({ formData: data, schema: validation });
      return responseJson;
    } else {
      this.parseApiErrorResponse(responseJson);
    }
  };

  // Customizable Area Start
  handleBread = () => {
    const state = this.props.history.location.state;
    this.setState({
      breadCrumData: state.breadCrum,
      details: state.breadCrum[2].attributes,
      accountId: state.account_id,
      solution_id: state.breadCrum[2].attributes.id,
      //redirectDetailPage:this.props.history?.location?.redirectDetailPage || false,
    });
  };

  additionalQuestions = () => {
    const solutionId = this.props.history.location.state.breadCrum[2]
      .attributes.id;
    const URL = `${configJSON.getAdditionalQuestion}/${solutionId}`;
    this.getAdditionalQuestions = sendAPIRequest(URL, {
      headers: { token: this.state.token, "Content-Type": "application/json" }
    });
  };

  questionsSubmit = (
    data: { question_id: string; answer: string; solution_id: string }[]
  ) => {
    this.setState({
      files: [],
      answers: data
    });
    const URL = configJSON.getAdditionalQuestionSubmit;
    this.postAdditionalQuestions = sendAPIRequest(URL, {
      headers: { token: this.state.token, "Content-Type": "application/json" },
      method: "POST",
      body: { account_questions: data }
    });
  };

  nonMendatoryQues = () => {
    const URL = configJSON.getAdditionalQuestionSubmit;
    this.postAdditionalQuestions = sendAPIRequest(URL, {
      headers: { token: this.state.token, "Content-Type": "application/json" },
      method: "POST",
      body: { account_questions:[{solution_id: this.state.solution_id}] }
    });
  }

  readMore = () => {
    this.setState({
      readMore: !this.state.readMore
    });
  };

  handleClickOpen = () => {
    if (this.state.buttonText === "Commit to Solution") {
      this.createCommit(this.state.solution_id)
      this.setState({ commitSucessMsg: true });
    } else if(this.state.additionalQuestion.length > 0){
      this.setState({ openRewardForm: true, openSucessModal: false });
    }else{
      this.setState({ openRewardForm: false, openSucessModal: false,openReportModel:true });
      this.nonMendatoryQues();
    }
  }

  claimStatusDataApi = () => {
    this.getSolutionApi = sendAPIRequest(configJSON.claimsData, {
      headers: { token: this.state.token, "Content-Type": "application/json"}
    });
  }

  handleClose = () => {
    this.setState({ openReportModel: false, files: [], errorMsg: false});
    if(this.state.commitSucessMsg === true){
      this.props.history.goBack()
    }
  };

  removeFile = (name: string | undefined) => {
    const temp = this.state.files.filter(item => item?.name !== name);
    this.setState({ files: [...temp] });
  };
  handleOnImportFile = (fileData: FileList) => {
    const zip = require('jszip')();
    const maxSize = 500 * 1024 * 1024;
    let sizeError : { [key: number]: boolean } = {};

    const temp = Array.from(fileData).map((el,index) => {
     
      if(maxSize>el.size){
        zip.file(el.name, el);
        sizeError[index] = false;
        return { name: el.name, file: el,solution_id:this.state.solution_id };
      }else{
        sizeError[index] = true;
      }
    });
   
    if(!Object.values(sizeError).includes(true)){
      zip.generateAsync({type: "blob"}).then((content: {
        size: number;
        type: string;
      }) => {
        this.setState({ files: temp, zipFile: content, errorMsg: false });
      });
    }else{
      this.setState({ errorMsg: true})
    }
  };

  clickInput = () => {
    const fileInput = document.getElementById(`fileInput`);
    fileInput && fileInput.click();
  };


  handleSubmit = () => {
    const files = this.state.files;
    const data = new FormData();
    let claims_id:number[] = this.state.questionId
    data.append("solution_id",this.state.solution_id);
    if(files.length===1 && files[0]){
      data.append("attachment",files[0].file);
    }else{
      const zipFileBlob = new Blob([this.state.zipFile], { type: "application/zip" });
      data.append("attachment",zipFileBlob,'attachment.zip');
    }
    
    claims_id.forEach((ele:any) => data.append('account_question_ids[]', ele))
    
    this.postClaimAPI = sendAPIRequest(configJSON.postClaimData, {
      headers: { token: this.state.token },
      method: "POST",
      body: data
    });
    this.setState({uploadMessage: configJSON.loading_message})
    this.setState({loaderData: true})
    this.setState({submitBtn: true})
  };

  handlePostAdditionalQuestions(responseJson:{
    success: boolean;
    message: string;
    errors: string;
    account_question_ids: [];
  }) {
    if (!responseJson.errors) {
      const quesId = responseJson.account_question_ids
      this.setState({ openReportModel: true, questionId: quesId});
    }
  }
  
  handlePostClaimAPI(responseJson:{
    id: number;
    account_id: number;
    solution_id: number;
    errors:string;
  }) {
    if (!responseJson.errors) {
      this.getClaimStatusApi = sendAPIRequest(`${configJSON.claimsStatus}/${responseJson.id}/claim_status`, {
        headers: { token: this.state.token },
      });
      this.setState({
        openSucessModal: true,
        openReportModel: true,
        openRewardForm: false,
      });
    }
  }
  
  handleCommitToSolution(responseJson:{
    data: {
      attributes: {commit_flag: boolean}
    };
    error:string
  }) {
    if (!responseJson.error) {
      if(responseJson.data.attributes.commit_flag){
        this.setState({ buttonText: configJSON.report_progress });
      } 
    }
  }

  buttonStatus(responseJson:{id: number, status: string, error:string}){
    if(!responseJson.error && responseJson.status === "pending" || "approved"){    
      this.setState({commitToSolutionBtn: true})
    }else{
      this.setState({commitToSolutionBtn: false})
    }
  }

  
  claimStatusData(responseJson:{solutions: {data:ClaimsAllData[] }, error?: string}){
    if(!responseJson.error){
      const solutinData = responseJson.solutions.data.find(data => data?.id == this.state?.solution_id)
      if(solutinData){
        const claimIds = solutinData.attributes.claims.data.map(data => Number(data.id));
        const latestClaimData = solutinData.attributes.claims.data.find(data => Number(data.id) === Math.max(...claimIds));
        if (latestClaimData) {         
          const { status } = latestClaimData.attributes;
            if (status === "pending" || status === "approved") {
                this.setState({
                    commitToSolutionBtn: true,
                    buttonText: configJSON.report_progress
                });
            } else {
                this.setState({
                    commitToSolutionBtn: false,
                    buttonText: configJSON.report_progress
                });
            }
        }
      }
    }
  }

  handleContactUs = () => {
    this.getContactUs = sendAPIRequest(configJSON.termsAndCondition);
  }

  contactUsDatas(responseJson:any){
    const contactus = responseJson.filter((data: any) => {
      return data.title === "Contact Us";
    });
    this.setState({ contact_us: contactus[0].description });
  }

  // Customizable Area End
}
