import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import { sendAPIRequest } from "../../../components/src/utils";

export const configJSON = require("./config");

export interface Props {
  history?: any;
  id?: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string;
  commingSoonArray: any;
  pointsEarnValue: number;
  rewardValue: number;
  pointsGraph: number;
  totalPoints: number;
  pointsCommited: number;
  solutionId: number;
  accountId: number;
  getProfileTypeData: any;
  profielType: any;
  userName: any;
  claimData: any;
  isExport: any;
  engagementData: any;
  impectData:any;
  firstName: any;
  emailData: any;
  switchData: boolean;
  employerData: any;
  errorMessage: string;
  fieldRequiredData: any;
  categotyCount: any;
  errorMsgPasValidation:{new_password:string, repeat_password:string};
  avgEmpImpact: any;
  contactUsData: {id:string, description:string, name:string, title:string };
  isCheckPDF: boolean;
  isCheckCSV: boolean;
  // Customizable Area End
}

interface SS {
  id?: any;
}

export default class DashboardPageEmployerController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getRportingID: string = "";
  getEngagementID: string = "";
  getImpectID: string = "";
  getprofileType: string = "";
  getEmployer: string = "";
  updateEmployer: string = "";
  getEmissionReductionCategory: string = "";
  getAvgEmpImpact: string = "";
  getContactUs: string = "";

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage)
    ];

    this.state = {
      token: "",
      isExport: false,
      commingSoonArray: [],
      rewardValue: 0,
      pointsEarnValue: 0,
      pointsGraph: 25,
      totalPoints: 200,
      pointsCommited: 150,
      getProfileTypeData: [],
      userName: [],
      profielType: "",
      claimData: [],
      engagementData: [],
      impectData:[],

      solutionId: 1,
      accountId: 161,
      firstName: "",
      emailData: "",
      switchData: false,
      employerData: {},
      errorMessage: "",
      fieldRequiredData: {old_password:'', new_password:'', repeat_password:'', first_name:'', last_name:''},
      categotyCount:[{}],
      errorMsgPasValidation:{new_password:'', repeat_password:''},
      avgEmpImpact:[{}],
      contactUsData: {id:"", description:"", name:"", title:"" },
      isCheckPDF: false,
      isCheckCSV: false,
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  // Customizable Area Start

  async componentDidMount() {
    await super.componentDidMount();
    const token =
      (typeof localStorage !== "undefined" &&
        localStorage.getItem("user_token")) ||
      "";
    this.setState({ token: token });

    this.handleReportingData()
    this.handleEngagementData();
    this.handleImpectData();
    this.handleProfileType();
    this.handleEmployerData();
    this.handleGetEmissionReductionCategory();
    this.handleGetAvgEmpImpact();
    this.handleContactUs();
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>): void {
    if (prevState.errorMessage !== this.state.errorMessage) {
      this.setState({ errorMessage: ""})
    }
  }
 
  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (apiRequestCallId == this.getRportingID) {
      responseJson.solutions?.data?.forEach((item: any, index: number) => {
        const scopCat = item?.attributes?.solutions?.scope3_sub_cat.data?.map((d:any) => {
          return d.attributes.scope3_categories[0]
        });

        const scopSubcat = item?.attributes?.solutions?.sub_cat?.data?.map((d:any) => {
          return d.attributes.name
        })

        const focus = item?.attributes?.solutions?.sub_cat?.data?.map((d:any) => {
          return d.attributes.categories[0]
        });
        
        const claimObject = {
          scop3Cat: scopCat,
          focusArea:focus,
          subCat: scopSubcat,
          employeesNumber: item.attributes.solutions.claim_count,
          carbonEmpected: item.attributes.solutions.estimate_carbon_impact
        };
        this.setState({ claimData: [...this.state.claimData, claimObject] })
      });
    }
    if (apiRequestCallId == this.getEngagementID) {
      this.setState({ engagementData: responseJson })
}
if (apiRequestCallId == this.getImpectID) {
  this.setState({ impectData: Object.values(responseJson) })
}
if (apiRequestCallId == this.getprofileType) {
  this.setState({ profielType: responseJson.data.attributes.profile_type})
  this.setState({ firstName: responseJson.data.attributes.first_name})
  this.setState({ emailData: responseJson.data.attributes.email})
  localStorage.setItem('profielType' ,this.state.profielType)
  localStorage.setItem('first_name' ,this.state.firstName)
  localStorage.setItem('email_data' ,this.state.emailData)
}
if(apiRequestCallId == this.getEmployer){
  this.setState({ employerData: responseJson})
  this.setState({switchData: responseJson?.notification})
}
if(apiRequestCallId == this.updateEmployer){
  this.setState({ errorMessage: responseJson?.message})
  if('Settings updated successfully, changes saved!!' === responseJson?.message){
    this.setState({employerData:{...this.state.employerData, old_password:'', new_password:'', repeat_password:''}})
  }
}
if(apiRequestCallId == this.getEmissionReductionCategory){
  this.setState({categotyCount:responseJson.category_counts})
}
if(apiRequestCallId == this.getAvgEmpImpact){
  this.setState({ avgEmpImpact: Object.entries(responseJson)})
}
if(apiRequestCallId == this.getContactUs){
  const contactus = responseJson.filter((data: any) => {
    return data.title === "Contact Us"        
  })
  this.setState({ contactUsData: contactus[0]})
}

    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Endthis.handleProgressbar(totalPoints, pointsCommited)

  // Customizable Area Start
  handleReportingData = () => {
    const header = {
      token: localStorage.getItem("user_token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getRportingID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      'bx_block_solutions/solutions/scope3_reporting.json'
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

    return this.getRportingID;
  };

  handleCsvData = () => {
    const url = `${configJSON.URL.baseURL}/${configJSON.getCsvFile}?token=${this.state.token}`
    window.open(url,'_blank');
  };

  handlePdfData = () => {
    const urlpdf = `${configJSON.URL.baseURL}/${configJSON.getPdfFile}?token=${this.state.token}`
    window.open(urlpdf, '_blank');
  };

  handleEngagementData = () => {
    const header = {
      token: localStorage.getItem("user_token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getEngagementID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      'bx_block_commit/commits/employee_engagement'
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

    return this.getEngagementID;
  };
  handleImpectData = () => {
    const header = {
      token: localStorage.getItem("user_token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getImpectID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      'bx_block_solutions/solutions/carbon_emission_overview'
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

    return this.getImpectID;
  };

  handleProfileType = () => {
    this.getprofileType = sendAPIRequest(configJSON.profieType, {
      headers: { token: this.state.token, "Content-Type": "application/json" },
    });
  }

  handleChange = () => {
    this.setState({switchData: !this.state.switchData})
  }

  handleEmployerData = () => {
    this.getEmployer = sendAPIRequest(configJSON.getEmployerData, {
      headers: { token: this.state.token, "Content-Type": "application/json" },
    });
  }

  handleGetEmissionReductionCategory = () => {
    this.getEmissionReductionCategory = sendAPIRequest(configJSON.emissionReductionCategory, {
      headers: { token: this.state.token, "Content-Type": "application/json" },
    });
  }

  handleGetAvgEmpImpact = () => {
    this.getAvgEmpImpact = sendAPIRequest(configJSON.avgIndividualEmp, {
      headers: { token: this.state.token, "Content-Type": "application/json" },
    });
  }

  handleChangeEmployerData = (e:any) => {
    if( e.target.name === 'new_password' || e.target.name ==='repeat_password'){
      this.validatePassword(e.target.value, e.target.name);
    }
    if(e.target.value?.length > 0 ){
      this.setState({fieldRequiredData:{...this.state.fieldRequiredData, [e.target.name]:''}})
    }
    else{
      let message;

      if (e.target.name === 'old_password') {
        message = '';
      } else if (e.target.name === 'first_name') {
        message = 'First name field is required';
      } else if (e.target.name === 'last_name') {
        message = 'Last name field is required';
      } else if (e.target.name === 'new_password') {
        message = '';
      } else if (e.target.name === 'repeat_password') {
        message = '';
      }

      this.setState({
        fieldRequiredData: {
          ...this.state.fieldRequiredData,
          [e.target.name]: message
        }
      });
      //this.setState({fieldRequiredData:{...this.state.fieldRequiredData, [e.target.name]:(e.target.name === 'old_password'? 'Old' : e.target.name === 'new_password'? 'New' : e.target.name === 'repeat_password'&& 'Repeat') + ' password field is required'}})
    }
    this.setState({employerData:{...this.state.employerData, [e.target.name]:e.target.value}})

  }

  handleUpdateEmployer = () => {
    let validate = this.handleValidation();
    if(validate)
    { this.updateEmployer = sendAPIRequest(configJSON.updateEmployerData, {
      headers: { token: this.state.token, "Content-Type": "application/json"},
      method: "PATCH",
      body: {
        "first_name": this.state.employerData.first_name,
        "last_name": this.state.employerData.last_name,
        "notification": this.state.switchData,
        "old_password": this.state.employerData.old_password,
        "new_password": this.state.employerData.new_password,
        "repeat_password": this.state.employerData.repeat_password, 
     }
    });
    this.setState({fieldRequiredData: {old_password:'', new_password:'', repeat_password:''}})
    this.setState({employerData:{...this.state.employerData, old_password:'', new_password:'', repeat_password:''}})
   }
  }

  handleValidation = () => {
    const field = this.state.employerData;
    const fieldRequiredData = { old_password: '', new_password: '', repeat_password: '', first_name: '', last_name: '' };

    if(!field.first_name && !field.last_name){
      fieldRequiredData.first_name = 'First name field is required';
      fieldRequiredData.last_name = 'Last name field is required';
    }else if(!field.first_name){
      fieldRequiredData.first_name = 'First name field is required';
    }else if(!field.last_name){
      fieldRequiredData.first_name = 'Last name field is required';
    }else{
      return true;
    }
   
    this.setState({ fieldRequiredData });
    return false;
  }
  

  handleClearData = () => {
    this.setState({employerData:{...this.state.employerData, old_password:'', new_password:'', repeat_password:''}})
    this.setState({fieldRequiredData: {old_password:'', new_password:'', repeat_password:'', first_name:'', last_name:""}})
    this.setState({ errorMessage: ""})
  }

   validatePassword = (password:any, name:any) => {
    const passwordRegex = /^(?=.[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]).{8,}$/;
 
    if (password.length > 0 && !passwordRegex.test(password) ) {
      this.setState({
        errorMsgPasValidation:
          {...this.state.errorMsgPasValidation,[name]:'Password must include at least one uppercase letter, one number, one special character, and be at least 8 characters long.',}
      });
    } else {
      this.setState({ errorMsgPasValidation: {...this.state.errorMsgPasValidation,[name]:''} });
    }
  }

  handleContactUs = () => {
    this.getContactUs = sendAPIRequest(configJSON.termsAndCondition);
  }

  handleCheck = (type: any) => {
    if(type === 'PDF'){
     this.setState({isCheckPDF: !this.state.isCheckPDF})
    }else{
     this.setState({isCheckCSV: !this.state.isCheckCSV})
    }
  }

  handleDownload = () => {
    if (this.state.isCheckPDF && this.state.isCheckCSV) {
      this.handlePdfData()
      this.handleCsvData()
    } else if (this.state.isCheckPDF) {
      this.handlePdfData()
    } else if (this.state.isCheckCSV) {
      this.handleCsvData()
    } 
  
    this.setState({ isCheckPDF: false, isCheckCSV: false});
  } 

  // Customizable Area End
}
